/**
 * OneTrust Consent Banner Component
 * ==================
 * Updates the links in the OneTrust Consent Banner based on the region.
 *
 */

// Utils
import { nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";

const selectors = {
	privacyPolicyAnchorTag: 'a[href*="privacy.html"]',
	privacyPolicyAlternativeAnchorTag: 'a[href*="privacy-policy.html"]',
	importantInformationAnchorTag: 'a[href*="important-information.html"]',
	cookiesPolicyAnchorTag: 'a[href*="cookies-policy.html"]',
	oneTrustConsentBannerId: 'onetrust-banner-sdk',
	hideOneTrustConsentBannerClass: 'hide-one-trust-banner'
};

const attributes = {
	href: 'href',
	style: 'style',
	displayBlock: 'display: block !important;'
};

const { country } = window.FSSA.currentLocale || {};

let termsAndConditionsLink, cookiesPolicyLink, privacyPolicyLink = "";

function getNoticeLinks() {
	const cookiesPolicyLinkElement = document.querySelector(selectors.cookiesPolicyAnchorTag);
	if (isElement(cookiesPolicyLinkElement)) {
		cookiesPolicyLink = cookiesPolicyLinkElement.getAttribute(attributes.href);
	}

	const importantInformationElement = document.querySelector(selectors.importantInformationAnchorTag);
	if (isElement(importantInformationElement)) {
		termsAndConditionsLink = importantInformationElement.getAttribute(attributes.href);
	}

	const privacyPolicyLinkElement = document.querySelector(selectors.privacyPolicyAnchorTag);
	if (isElement(privacyPolicyLinkElement)) {
		privacyPolicyLink = privacyPolicyLinkElement.getAttribute(attributes.href);
	} else {
		const privacyPolicyAlternativeLinkElement = document.querySelector(selectors.privacyPolicyAlternativeAnchorTag);
		if (isElement(privacyPolicyAlternativeLinkElement)) {
			privacyPolicyLink = privacyPolicyAlternativeLinkElement.getAttribute(attributes.href);
		}
	}
}

function updateOneTrustBannerNoticeLinks() {
	if (cookiesPolicyLink) {
		document.querySelectorAll('[aria-label*="FSI Cookie Policy"]').forEach((a) => {
			a.href = cookiesPolicyLink;
			a.target = "_blank";
		});
	}

	if (termsAndConditionsLink) {
		document.querySelectorAll('[aria-label="FSI Terms and Conditions"]').forEach((a) => {
			a.href = termsAndConditionsLink;
			a.target = "_blank";
		});
	}

	if (privacyPolicyLink) {
		document.querySelectorAll('[aria-label="FSI Privacy Policy"]').forEach((a) => {
			a.href = privacyPolicyLink;
			a.target = "_blank";
		});
	}

	document.querySelectorAll('.ot-optout-signal span').forEach((optOutTextElement) => {
		let optOutText = optOutTextElement.innerHTML;
		if (optOutText) {
			const regex = "\\{(.*?)\\}";
			const matches = [...optOutText.matchAll(regex)];
			if (matches.length > 0) {
				let matchCounter = 1;
				for (const match in matches) {
					if (matches.length >= 2) {
						if (matchCounter == 1) {
							const optOutCaliforniaPrivacyLinkHTML = '<a class="ot-opt-out-notice-link" href="https://www.mufgamericas.com/privacy/california-consumer-privacy-statement" target="_blank">' + matches[match][1] + '</a>';
							optOutText = optOutText.replace(matches[match][0], optOutCaliforniaPrivacyLinkHTML);
						} else {
							optOutText = optOutText.replace(matches[match][0], createFsiPrivacyLinkElement(matches[match][1], privacyPolicyLink).outerHTML);
						}
					} else {
						optOutText = optOutText.replace(matches[match][0], createFsiPrivacyLinkElement(matches[match][1], privacyPolicyLink).outerHTML);
					}
					matchCounter++;
				}

				const optOutTextNewWrapper = document.createElement('span');
				optOutTextNewWrapper.innerHTML = optOutText;
				optOutTextElement.innerHTML = optOutTextNewWrapper.innerHTML;
			}
		}
	});

	if (country == 'us') {
        document.querySelectorAll('.ot-sdk-show-settings').forEach((b) => {
            b.innerHTML = "Do Not Sell or Share My Data";
        });
    }
}

function createFsiPrivacyLinkElement(optOutPrivacyLinkText, privacyPolicyLink) {
	const optOutPrivacyLinkTag = document.createElement('a');
	const linkText = document.createTextNode(optOutPrivacyLinkText);
	optOutPrivacyLinkTag.appendChild(linkText);
	optOutPrivacyLinkTag.classList.add("ot-opt-out-notice-link");
	optOutPrivacyLinkTag.href = privacyPolicyLink;
	optOutPrivacyLinkTag.target = "_blank";

	return optOutPrivacyLinkTag;
}

export default (function oneTrustConsentBannerFSSA() {
	function onDocumentReady() {
		getNoticeLinks();
		window.addEventListener("load", (event) => {
			setTimeout(function() {
				updateOneTrustBannerNoticeLinks();
				const oneTrustMainBanner = document.getElementById(selectors.oneTrustConsentBannerId);
				if (isElement(oneTrustMainBanner) && !document.body.classList.contains(selectors.hideOneTrustConsentBannerClass)) {
				    oneTrustMainBanner.setAttribute (attributes.style, attributes.displayBlock);
				}
			}, 500);
		});
	}

	if (document.readyState !== "loading") {
		onDocumentReady();
	} else {
		document.addEventListener("DOMContentLoaded", (event) => {
			onDocumentReady();
		});
	}
})();