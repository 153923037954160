/**
 * Date field utilities
 * ====================
 * Index
 *
 * - getISODateString(date)
 * - isDate(date)
 * - removeDates(data)
 * - setDateString(dateGroup, dates)
 */

/**
 * Get date string in standardized format: `YYYY-MM-DD`
 * Utilizes `Date.prototype.toISOString()` with the time portion removed
 * @param   {Date}  date
 * @return  {String}
 */
export function getISODateString(date) {
    if (!isDate(date)) {
        return "";
    }
    return date.toISOString().split("T")[0];
}

/**
 * Test for valid Date object
 * E.g. Good to check before calling further date-specific methods, which will
 * throw JS errors on non-date items
 * @param   {String|Date}  dateString
 * @return  {Date}
 */
export function isDate(date) {
    const DATE = new Date(date);
    if (Number.isNaN(DATE.getTime())) {
        return;
    }
    return DATE;
}

/**
 * Clean-up utility
 * Remove any `dateN` properties from data set
 * @param   {Object}  data -- Filtering options
 * @return  {Object}
 */
export function removeDates(data) {
    const KEY_NAMES = ["dateFromYear", "dateToYear", "dateFromMonth", "dateToMonth", "dateFromDay", "dateToDay"];
    const DATA_KEYS = Object.keys(data);

    return DATA_KEYS.reduce((collection, key) => {
        if (!KEY_NAMES.includes(key)) {
            collection[key] = data[key];
        }
        return collection;
    }, {});
}

/**
 * Build `dateFrom` & `dateTo` date strings with sensible defaults,
 * (where data is missing).
 * Useful for date filtering calculations/comparisons.
 *
 * @param   {String}  dateGroup -- "dateFrom" (Default: `1970-01-01`) |
 *                                 "dateTo" (Default: `{CURRENT_YEAR}-12-31`)
 * @param   {Object}  dates -- Containing all the date filtering property key/values
 * @return  {String} -- (e.g "2021-01-31")
 */
export function setDateString(dateGroup, dates = {}) {
    // Maximum days in each month (no need for leap year calc.)
    // First item is deliberately blanked to allow month value to match array index
    const MONTH_DAYS = [null, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const DEFAULT_FROM = [1970, 1, 1]; // UNIX epoch
    const DEFAULT_TO = [new Date().getFullYear(), 12, 31]; // End of current year

    return ["Year", "Month", "Day"]
        .map((period, index) => {
            const defaults = dateGroup === "dateFrom" ? DEFAULT_FROM : DEFAULT_TO;
            const monthIndex = Number(dates[`${dateGroup}Month`]);
            let value = dates[`${dateGroup}${period}`];
            // Without day value for `dateTo`, last day of month a better
            // default choice than first day
            if (dateGroup === "dateTo" && period === "Day" && !value && !Number.isNaN(monthIndex)) {
                value = MONTH_DAYS[monthIndex];
            }

            return value || defaults[index];
        })
        .join("-");
}

export default {
    getISODateString,
    isDate,
    removeDates,
    setDateString
};
