/**
 * Accordion API
 * =============
 * Can be used by accordion component, or by any other component that requires
 * enhancing with accordion-type functionality.
 *
 * Configuration options
 * ---------------------
 *
 * -- `ariaUntouched`:
 *      Boolean (default: false)
 *      Ensures ARIA markup is not removed when accordion instance is destroyed.
 *
 * -- `icon`:
 *      String (default: "chevron")
 *      Accepted values: "chevron"|"plus"
 *      Choice of button icon, e.g. down/up chevron or plus/minus icon.
 *
 * -- `openOnLoad`:
 *      String|Null (default: null)
 *      Accepted values: "first"|"all"|null
 *      At page load, start the accordion with open item(s) or none.
 *
 * -- `skipSetup`:
 *      Boolean (default: false)
 *      Set to `true` by the Accordion component, as it already includes the full
 *      accordion markup.
 *
 * -- `targetSelector`:
 *      String (default: "[data-attribute-target]")
 *      Selector that identifies the opening/closing content panels.
 *
 * -- `triggerSelector`:
 *      String (default: "[data-attribute-trigger]")
 *      Selector that identifies the element that becomes the item `button`.
 *
 * -- `type`:
 *      String (default: "multi")
 *      Accepted values: "multi"|"single"
 *      Setting to determine if all accordion panels work independently of each other,
 *      or if only one panel should be open at a time.
 *
 * Usage
 * -----
 *
 * ```
 * // const accordion = Accordion(ELEMENT, CONFIGURATION);
 * // E.g.
 * const accordion = Accordion(".accordion", {
 *     openOnLoad: "first",
 *     targetSelector: ".accordion-content",
 *     triggerSelector: ".button-placement",
 *     type: "single"
 * });
 * ```
 *
 * API methods (once instantiated)
 * -------------------------------
 *
 * -- `accordion.setup();`
 *     Enhances the accordion HTML element, target panels & triggers with the necessary
 *     `data-accordion*` attributes/values and CSS classes.
 *
 *     If accordion previously destroyed, `setup()` needs to be called BEFORE
 *     any new call to `init()`.
 *
 * -- `accordion.init()`;
 *     Launches the `accordion` functionality.
 *
 * -- `accordion.destroy()`;
 *     Destroy the `accordion`.
 *
 *     Removes all the HTML attribute enhancements and CSS classes.
 *     Returns all trigger elements back from `button` elements.
 */

// Constants
import EVENTS from "../constants/events";

// Utils
import A11y from "../utils/a11y";
import Panel from "../utils/panel";
import Template from "../utils/template";
import { nodeListArray } from "../utils/dom";
import { isElement, swapTag } from "../utils/element";

const selectors = {
    accordion: "data-accordion",
    open: "data-open",
    target: "data-accordion-target",
    trigger: "data-accordion-trigger"
};

function createIcon(icon) {
    icon = icon || "plus";
    return Template(`#template-svgicon${icon === "plus" ? "--plus" : ""}`, { name: icon });
}

function createButtons(element, config = {}) {
    const { icon } = config;
    const originalTagName = element.tagName;
    const button = swapTag(element, "button");

    // Store original tag-type
    button.setAttribute(selectors.trigger, originalTagName);
    button.setAttribute("type", "button");
    button.innerHTML = `${createIcon(icon)} ${button.innerHTML}`;
    return button;
}

function getType(accordion) {
    return accordion.getAttribute(selectors.accordion) || "multi";
}

/**
 * getTargetsAndTriggers:
 * Recapture the trigger & target elements from wrapper; they may have
 * changed since API was originally called, e.g. when original trigger
 * elements are replaced with buttons.
 */
function getTargetsAndTriggers(accordion, config = {}) {
    const {
        // Add fallbacks in case config is not available, i.e. on delegated event
        targetSelector = `[${selectors.target}]`,
        triggerSelector = `[${selectors.trigger}]`
    } = config;

    return {
        targets: nodeListArray(accordion.querySelectorAll(targetSelector)),
        triggers: nodeListArray(accordion.querySelectorAll(triggerSelector))
    };
}

/**
 * getPrimaryTarget:
 * For the delegated button click event, capture the closest trigger button.
 * If clicked anywhere else, fail quietly.
 */
function getPrimaryTarget(e) {
    const { target } = e;
    const accordion = target.closest(`[${selectors.accordion}]`);
    const button = target.closest(`button[${selectors.trigger}]`);

    if (!isElement(button)) {
        return {};
    }

    return {
        button,
        buttons: getTargetsAndTriggers(accordion).triggers,
        type: getType(accordion)
    };
}

/**
 * buttonClickEvent:
 * Delegated click event for the accordion buttons.
 * Event is attached/removed from the accordion element.
 * More efficient & robust than adding/removing events on
 * individual accordion button triggers.
 */
function buttonClickEvent(e) {
    const { button, buttons, type } = getPrimaryTarget(e);
    const { target } = e;

    if (!button) {
        return;
    }

    // Open/close selected...
    const CONTROLS = Panel.toggle(button);

    if (type === "single") {
        // Close the rest...
        buttons.filter((b) => b !== target && b !== button).forEach((b) => Panel.close(b));
    }

    if (button.getAttribute("aria-expanded") === "true") {
        // Broadcast change details via custom event
        window.dispatchEvent(
            new CustomEvent(EVENTS.ACCORDION.ACCORDION_OPENED, {
                detail: CONTROLS
            })
        );
    }
}

/**
 * setup accordion:
 * Apply the accordion data-attributes to the accordion element
 */
export function setup(config) {
    const accordion = this;
    const { openOnLoad, type } = config;
    const { targets, triggers } = getTargetsAndTriggers(accordion, config);

    if (openOnLoad) {
        accordion.setAttribute(selectors.open, openOnLoad);
    }

    accordion.setAttribute(selectors.accordion, type);
    triggers.forEach((trigger) => trigger.setAttribute(selectors.trigger, trigger.tagName));
    targets.forEach((target) => target.setAttribute(selectors.target, ""));
}

/**
 * destroy accordion:
 * Remove the accordion data-attributes previously added to the accordion element.
 * Reset things to how we originally found them.
 * @note:
 * - When destroying an accordion, leave the target `id` attributes alone;
 *   avoids danger of removing manually added IDs
 */
export function destroy(config) {
    const accordion = this;
    const { targets, triggers } = getTargetsAndTriggers(accordion, config);
    const { ariaUntouched } = config;

    // Remove attributes from accordion wrapper
    accordion.removeEventListener("click", buttonClickEvent, false);
    accordion.removeAttribute(selectors.accordion);
    accordion.removeAttribute(selectors.open);

    // Target-specific actions
    targets.forEach((target) => {
        target.removeAttribute(selectors.target);
        target.classList.remove("closed", "open");
        target.hidden = false;
    });

    // Trigger-specific actions (order of tasks is important)
    triggers.forEach((trigger) => {
        const icon = trigger.querySelector("svg");
        const originalTag = trigger.getAttribute(selectors.trigger);

        if (isElement(icon)) {
            icon.remove();
        }

        if (!ariaUntouched) {
            A11y.removeControls(trigger);
        }

        trigger.removeAttribute(selectors.trigger);
        trigger.removeAttribute("type");
        swapTag(trigger, originalTag);
    });
}

/**
 * initialize accordion:
 * Prompt the accordion into action. Can be called post setup() step.
 *
 * @notes:
 * - Kept as separate step in case immediate run is not acceptable
 * - setup() runs as part of Accordion(...) creation step unless `skipSetup`
 *      flag passed in options
 */
export function init(config) {
    if (!window.FSSA || window.FSSA.isAuthor) {
        return;
    }

    const accordion = this;
    const { openOnLoad, skipTransitionEnd } = config;
    const { targets, triggers } = getTargetsAndTriggers(accordion, config);
    const buttons = triggers.map((trigger) => createButtons(trigger, config));

    accordion.addEventListener("click", buttonClickEvent, false);

    targets.forEach((target, index) => {
        target.setAttribute(selectors.target, "");

        // Only writes controls to elements if there are none already
        A11y.setControls(buttons[index], target, {
            prefix: "accordion-item"
        });

        // Obey settings overrides:
        // 1.) Prevent attaching own `onTransitionEnd` event
        //     Allows outside scripts to control this instead,
        //     e.g. mobile navigation accordion where event already attached to
        //          each subnav panel
        if (!skipTransitionEnd) {
            Panel.transitionEnd(target);
        }

        if (!openOnLoad || (openOnLoad === "first" && index > 0)) {
            Panel.close(buttons[index], { immediate: true });
        } else {
            Panel.open(buttons[index], { immediate: true });
        }
    });
}

/**
 * Expose the Accordion API & public methods for wider use
 */
export default function Accordion(accordion, configuration = {}) {
    if (!isElement(accordion)) {
        console.warn(`Accordion: ${accordion} is not an element.`);
        return;
    }

    // Merge configuration with default settings
    const config = {
        ariaUntouched: false,
        icon: "chevron",
        openOnLoad: accordion.getAttribute(selectors.open) || null,
        skipSetup: false,
        skipTransitionEnd: false,
        targetSelector: `[${selectors.target}]`,
        triggerSelector: `[${selectors.trigger}]`,
        type: getType(accordion),
        ...configuration
    };

    // When the accordion markup already has the correct data-attributes, skip setup call
    // i.e. the Accordion component
    if (!config.skipSetup) {
        setup.call(accordion, config);
    }

    // Returned API methods
    return {
        setup: () => setup.call(accordion, config),
        destroy: () => destroy.call(accordion, config),
        init: () => init.call(accordion, config)
    };
}
