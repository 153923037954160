/**
 * Modal API
 * =========
 * An layered element overlaying the main page content. Can be used to present general
 * content or lock visitors into an area until a required decision is made,
 * e.g. legal compliance content/disclaimers.
 *
 * Configuration options
 * ---------------------
 * Any configuration settings passed-in during instantiation get merged with the default
 * settings; only supply options specific for your needs where they differ.
 *
 * -- `trigger`:
 *     Element (default: null)
 *     The link/button element that opens the modal.
 *
 * -- `type`:
 *     String (default: null)
 *     Accepted values: "iframe" | "element"
 *     Describes where the modal content will come from:
 *     - `iframe`: A full CMS page, i.e. Case Study Template pages
 *     - `element`: An element either in-page or created on the fly by other JavaScript,
 *        e.g. Card Profile video buttons
 *     This input determines which other configuration properties are needed, e.g.
 *     `url` string (iframe) / `content` object (element) to complete modal instantiation.
 *
 * -- `url`:
 *     String (default: null)
 *     The URL string of the page to be loaded as an iframe.
 *     - Expects `type` value to be "iframe"
 *     - Becomes the `<iframe src/>` value
 *
 * -- `content`:
 *     Object (default: {})
 *     Property keys: "body" (required), "header", "footer"
 *     The modal content values for body, header & footer sections
 *     - Expects `type` value to be "element"
 *     - Each property accepts element node or HTML string
 *
 * -- `additionalCss`:
 *     Array (default: [])
 *     Additional CSS names to be appended to the modal element `class` attribute, for
 *     additional styling control/variation
 *     - Array expects string values (without the leading dot) for each CSS class
 *
 * -- `closeIcon`:
 *     String (default: null)
 *     Display the named icon in the close button.
 *
 * -- `closeTextHidden`
 *     Boolean (default: false)
 *     Visually hide the close button text; it remains available to assistive technology,
 *     i.e. screen readers
 *     - If hiding the text, ensure an icon is set; button displays empty otherwise...
 *
 * -- `locked`:
 *     Boolean (default: false)
 *     Prevent the modal from displaying the close button, or including additional
 *     closing triggers: clicking the modal mask area or pressing the Escape key.
 *     E.g. this will be set to `true` when launching the compliance slam-door modal.
 *
 * -- `openCallback`:
 *     Function (default: empty function)
 *     Optional extra function that will be called whenever a particular modal opens.
 *
 * -- `closeCallback`:
 *     Function (default: empty function)
 *     Optional extra function that will be called whenever a particular modal closes.
 *
 * Usage
 * -----
 * For live examples, see the Case Study Teaser or the Navigation "Region" link
 *
 * ```
 * const link = LINK_OR_BUTTON_ELEMENT;
 * const exampleModal = Modal(CONFIGURATION_OPTIONS);
 *
 * link.addEventListener("click", (e) => {
 *     e.preventDefault();
 *     exampleModal.open();
 * }, false);
 * ```
 *
 */

// Constants
import EVENTS from "../constants/events";

// APIs
import tabTrap from "./tabTrap";

// Utils
import { setWcmModeDisabled } from "../utils/aem";
import { htmlStringToNodeArray } from "../utils/dom";
import { createElement, isElement } from "../utils/element";
import Template from "../utils/template";

const modalSelector = `[data-modal="overlay"]`;

const documentBody = document.querySelector("body");
const page = document.querySelector(`[data-modal="background"]`);
const mask = document.querySelector(`[data-modal="mask"]`);
const modal = document.querySelector(modalSelector);

let currentContext;
let scrollPositionY = 0;
let tabTrapping;

function getElements() {
    return {
        closeBtn: modal.querySelector(`[data-modal="close"]`),
        body: modal.querySelector(`[data-modal="body"]`),
        header: modal.querySelector(`[data-modal="header"]`),
        footer: modal.querySelector(`[data-modal="footer"]`)
    };
}

/**
 * Merge configuration with default settings
 * @param   {Object}  configuration
 * @return  {Object}
 */
function setDefaults(configuration = {}) {
    // @note: See the document comment section at the top of this file for details
    // about each configuration option.
    return {
        trigger: null,
        type: null,
        url: null,
        content: {},
        additionalCss: [],
        closeIcon: null,
        closeTextHidden: false,
        locked: false,
        openCallback: () => {},
        closeCallback: () => {},
        ...configuration
    };
}

function isPageScrollable(element) {
    return element.clientHeight > document.documentElement.clientHeight;
}

function setElementContent(content) {
    const modalSection = getElements();

    return Object.keys(content).forEach((key) => {
        // Handle Nodes AND HTML strings - just in case
        const nodes = (() => {
            if (typeof content[key] !== "string") {
                return [content[key]];
            }

            return htmlStringToNodeArray(content[key]);
        })();

        modalSection[key].hidden = false;
        return modalSection[key].append(...nodes);
    });
}

function setIframeContent(url, loader) {
    const queryString = setWcmModeDisabled(url);
    const iframe = createElement("iframe", {
        src: `${url.split("?")[0]}?${queryString}`
    });

    iframe.addEventListener(
        "load",
        (e) => {
            // Remove loading animation
            if (loader && loader.parentNode) {
                loader.parentNode.removeChild(loader);
            }
            iframe.classList.add("loaded");
        },
        false
    );

    return iframe;
}

function loadingAnimation(element) {
    const animation = createElement("div", {
        class: "loading-animation"
    });

    animation.innerHTML = `<div class="loader"></div>`;
    element.append(animation);
    return animation;
}

function getContent(source) {
    const { body } = getElements();
    const { type, content, url } = source;

    if (type === "iframe" && url) {
        const loader = loadingAnimation(body);
        body.append(setIframeContent(url, loader));
        return;
    }

    // Fallback to direct element content
    // No need for loading animation; in-page content injection is immediate
    return setElementContent(content);
}

function detectEscKey(e) {
    const keyValue = e.key.toUpperCase();
    if (keyValue == "ESC" || keyValue == "ESCAPE") {
        close();
    }
}

function maskClose(e) {
    const { target } = e;
    if (mask === target) {
        close();
    }
}

export function open() {
    const { closeBtn } = getElements();
    const { id, additionalCss, closeIcon, closeTextHidden, locked, openCallback } = this;
    const btnText = closeBtn.querySelector(".btn-text");

    currentContext = this;

    scrollPositionY = window.scrollY;
    page.style.top = `${scrollPositionY * -1}px`;
    modal.classList.add(...additionalCss);
    mask.hidden = false;

    getContent(currentContext);

    if (!locked) {
        closeBtn.hidden = false;
        closeBtn.addEventListener("click", close, false);
        mask.addEventListener("click", maskClose, false);
        modal.addEventListener("keydown", detectEscKey, false);

        if (closeTextHidden) {
            btnText.classList.add("visually-hidden");
        }

        if (closeIcon) {
            const icon = Template("#template-svgicon", { name: closeIcon });
            closeBtn.append(...htmlStringToNodeArray(icon));
        }
    }

    // Quick solution to allow animation to fire
    setTimeout(() => {
        page.setAttribute("aria-hidden", true);

        // FWD-105: Prevents page contents shift when launching overlay
        if (isPageScrollable(page)) {
            page.style.overflowY = "scroll";
        }

        documentBody.setAttribute("data-modal-open", true);
        tabTrapping = tabTrap(modalSelector);
        openCallback(currentContext);

        window.dispatchEvent(
            new CustomEvent(EVENTS.MODAL_OVERLAY.MODAL_OPENED, {
                detail: { id, modal }
            })
        );
    }, 250);
}

export function close() {
    const { closeBtn, body, footer, header } = getElements();
    const { id, trigger, additionalCss, locked, closeCallback } = currentContext || setDefaults({});
    const btnText = closeBtn.querySelector(".btn-text");
    const icon = closeBtn.querySelector("svg");

    documentBody.removeAttribute("data-modal-open");
    page.removeAttribute("aria-hidden");
    page.removeAttribute("style");
    modal.classList.remove(...additionalCss);

    window.scrollTo(0, scrollPositionY || 0);

    if (!locked) {
        closeBtn.hidden = true;
        closeBtn.removeEventListener("click", close, false);
        mask.removeEventListener("click", maskClose, false);
        modal.removeEventListener("keydown", detectEscKey, false);

        btnText.classList.remove("visually-hidden");

        if (icon) {
            icon.remove();
        }
    }

    // Empty/reset modal section areas
    [body, footer, header].forEach((section) => {
        if (section !== body) {
            section.hidden = true;
        }

        section.innerHTML = "";
    });

    // Quick solution to allow animation to fire
    setTimeout(() => {
        mask.hidden = true;

        // Return focus to trigger element (if there was one) and location path not contains anchor
        if (isElement(trigger) && !location.href.includes("#")) {
            trigger.focus();
        }

        tabTrapping.remove();
        closeCallback(currentContext);

        window.dispatchEvent(
            new CustomEvent(EVENTS.MODAL_OVERLAY.MODAL_CLOSED, {
                detail: { id, modal }
            })
        );
    }, 250);
}

/**
 * Expose the Modal API & public methods for wider use
 */
export default function Modal(configuration = {}) {
    if (!isElement(modal)) {
        console.warn(`Modal API:  modal not found in page.`);
        return;
    }

    const config = setDefaults(configuration);

    // Returned API methods
    return {
        open: () => open.call(config),
        close: () => close.call(config)
    };
}
