/**
 * Jump Links Component
 * ====================
 * Gives site visitors a way to jump straight to content further down the page,
 * plus a "Back-to-top" link (visible when scrolled down a little).
 *
 * Titles are added to the jump links panel when they are marked to do so by
 * content editors. This is done from within the Title component's dialog settings.
 *
 * UI presentation is different, depending on viewport width:
 * - Mobile/tablet: form select box
 * - Desktop: Row of anchor links (styled as buttons)
 * - If JavaScript not available, only the "Back-to-top" link displays
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { htmlStringToNodeArray, nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";
import { throttle } from "../../../../global/js/utils/event";
import { prefersReducedMotion } from "../../../../global/js/utils/window";

import Template from "../../../../global/js/utils/template";

const jumpLinks = document.querySelector("nav.jumplinks");

const selectors = {
    dataAttr: "data-jumplinks",
    templates: {
        option: "#jumplinksOptionTemplate",
        listItem: "#jumplinksListItemTemplate"
    }
};

const ELEMENTS = {};

function updateElements() {
    ELEMENTS.TOP = jumpLinks.querySelector("a.btn-top");
    // Mobile/tablet presentation: form select box
    ELEMENTS.FORM = jumpLinks.querySelector("form.jumplinks-selector");
    ELEMENTS.FORM_SELECT = ELEMENTS.FORM.querySelector("select");
    // Desktop presentation: ordered list
    ELEMENTS.LIST = jumpLinks.querySelector("div.jumplinks-list");
    ELEMENTS.LIST_ORDERED = ELEMENTS.LIST.querySelector("ol");
}

function backToTop() {
    const jumpToOption = jumpLinks.querySelector("option");
    const scrollBuffer = 740;

    ELEMENTS.TOP.style.opacity = 0;
    ELEMENTS.TOP.addEventListener("click", (e) => (jumpToOption.selected = true), false);

    window.addEventListener(
        "scroll",
        throttle(() => {
            if (window.scrollY > scrollBuffer) {
                ELEMENTS.TOP.style.opacity = 1;
                return;
            }
            ELEMENTS.TOP.style.opacity = 0;
        }, 750),
        false
    );
}

function getData(titles) {
    const reverseOrder = jumpLinks.getAttribute("data-orderby") === "reverse";
    const data = titles.map((title) => {
        return {
            id: title.getAttribute("id"),
            title: title.getAttribute(selectors.dataAttr)
        };
    });

    return reverseOrder ? data.reverse() : data;
}

function setJumpLinks() {
    const titles = nodeListArray(document.querySelectorAll(`[${selectors.dataAttr}]`));
    const data = getData(titles);

    titles.forEach((title, index) => {
        const { option, listItem } = selectors.templates;
        title.setAttribute("tabindex", -1);
        ELEMENTS.FORM_SELECT.append(...htmlStringToNodeArray(Template(option, data[index])));
        ELEMENTS.LIST_ORDERED.append(...htmlStringToNodeArray(Template(listItem, data[index])));
    });
}

function scrollTo(selector) {
    const element = document.querySelector(selector);
    const destination = window.scrollY + element.getBoundingClientRect().top - 100;

    const checkScrollEnd = () => {
        // Differential buffer added to test to avoid occasional inaccurate jump
        if (window.scrollY > destination || destination - window.scrollY > 10) {
            window.requestAnimationFrame(checkScrollEnd);
            return;
        }
        ELEMENTS.TOP.style.opacity = 1;
        element.focus();
    };

    window.requestAnimationFrame(checkScrollEnd);

    window.scrollTo({
        top: destination,
        behavior: !prefersReducedMotion() ? "smooth" : "auto"
    });
}

export default (function jumpLinksNavigation() {
    if (!isElement(jumpLinks)) {
        return;
    }

    updateElements();
    backToTop();
    setJumpLinks();

    // Mobile/Tablet form jump behaviour
    ELEMENTS.FORM_SELECT.addEventListener(
        "change",
        (e) => {
            const value = e.target.value;
            if (value) {
                scrollTo(e.target.value);
            }
        },
        false
    );

    // Desktop links jump behaviour
    ELEMENTS.LIST.addEventListener(
        "click",
        (e) => {
            const { target } = e;
            const anchor = target.closest("a");
            e.preventDefault();

            if (isElement(anchor)) {
                scrollTo(anchor.getAttribute("href"));
            }
        },
        false
    );

    // Listen-out for viewport status &/or any changes
    window.addEventListener(
        EVENTS.VIEWPORT_WATCHER.DESKTOP_SWITCH,
        (e) => {
            const { matches } = e.detail;
            ELEMENTS.FORM.hidden = matches;
            ELEMENTS.LIST.hidden = !matches;
        },
        false
    );
})();
