/**
 * Timeline Component
 * ==================
 * Timeline slide animations only trigger:
 *
 * 1). If visitor's browser/OS is NOT set to `prefers-reduced-motion`
 * 2.) When viewport is at tablet width, or wider
 *
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { isElement } from "../../../../global/js/utils/element";
import { nodeListArray } from "../../../../global/js/utils/dom";
import { prefersReducedMotion } from "../../../../global/js/utils/window";

const timelines = nodeListArray(document.querySelectorAll(".timeline[data-timeline]"));

const selectors = {
    slide: "li.timeline-slide",
    introduction: ".timeline-introduction"
};

function setUpTimelines() {
    timelines.forEach((timeline) => {
        const slides = nodeListArray(timeline.querySelectorAll(`${selectors.slide}`));
        slides.forEach((slide) => {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0].intersectionRatio <= 0) {
                        return;
                    }

                    slide.classList.add("animation");
                    observer.unobserve(slide);
                },
                { threshold: 0.15 }
            );

            slide.style.opacity = 0;
            observer.observe(slide);
        });
    });
}

function tearDownTimelines() {
    timelines.forEach((timeline) => {
        const slides = nodeListArray(timeline.querySelectorAll(`${selectors.slide}`));
        slides.forEach((slide) => {
            slide.classList.remove("animation");
            slide.style.opacity = 1;
        });
    });
}

function handleViewportSwitch(e) {
    const { matches } = e.detail;

    if (!matches) {
        tearDownTimelines();
        return;
    }

    setUpTimelines();
}

function introAnimation() {
    timelines.forEach((timeline) => {
        const intro = timeline.querySelector(`${selectors.introduction}`);
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].intersectionRatio <= 0) {
                    return;
                }

                intro.classList.add("animation");
                observer.unobserve(intro);
            },
            { threshold: 0.85 }
        );

        if (prefersReducedMotion() || !isElement(intro)) {
            return;
        }

        intro.style.opacity = 0;
        observer.observe(intro);
    });
}

export default (function initTimeline() {
    if (prefersReducedMotion() || !window.FSSA || window.FSSA.isAuthor) {
        return;
    }

    introAnimation();
    window.addEventListener(EVENTS.VIEWPORT_WATCHER.TABLET_SWITCH, handleViewportSwitch, false);
})();
