/**
 * Translation/Localization (i18n) utilities
 * =========================================
 * Index
 *
 * - runTranslations(language, scope)
 * - translate(key)
 * - translateText(element, language)
 */

import { nodeListArray } from "./dom";
import { isElement } from "./element";
import { replacePlaceholders } from "./template";

const I18N_ATTRIBUTE = "data-i18n-key";

/**
 * Translate all elements contained within parent element's scope.
 * Scope defaults to `document`, if not provided.
 *
 * @param   {String}  language  -- Two-letter language code, e.g. `en`, `ja`, `zh`, etc.
 * @param   {[type]}  scope     -- A parent element to confine changes to.
 *                                 Defaults to `document`, if not provided
 * @return  {Array}             -- Array of elements
 */
export function runTranslations(language, scope) {
    return nodeListArray((isElement(scope) || document).querySelectorAll(`[${I18N_ATTRIBUTE}]`)).forEach((element) =>
        translateText(element, language)
    );
}

/**
 * Return an i18n property stored on the `FSSA.i18n` global variable
 * @param   {String}  key  -- "COMPONENT_IDENTIFIER.LANGUAGE_CODE.PROPERTY_NAME"
 *                            e.g "countryselector.en.heading"
 * @return  {String}       -- String is empty, if not found
 */
export function translate(key) {
    const [COMPONENT, LANGUAGE, PROPERTY] = key.split(".");
    try {
        return window.FSSA.i18n[COMPONENT][LANGUAGE][PROPERTY];
    } catch (e) {
        return "";
    }
}

/**
 * Change the text of an element that holds a localization key attribute
 * @param   {Element}  element   -- Function expects element to have `data-i18n-key` attribute with value
 * @param   {String}  language   -- Two-letter language code, e.g. `en`, `ja`, `zh`, etc.
 * @return  {Element|Undefined}
 */
export function translateText(element, language) {
    if (!isElement(element) || !element.getAttribute(I18N_ATTRIBUTE)) {
        return;
    }

    const COPY_ORIGINAL = element.textContent.trim();
    const KEY = replacePlaceholders(element.getAttribute(I18N_ATTRIBUTE), {
        LANGUAGE: language
    });

    // Leave the original text unchanged, if no translation found
    element.textContent = translate(KEY) || COPY_ORIGINAL;
    return element;
}

export default {
    runTranslations,
    translate,
    translateText
};
