/**
 * Window utilities
 * ================
 * Index
 *
 * - cssSupports(property, value)
 * - isOverlay()
 * - isWindowTop()
 * - matchMediaOnChange()
 * - overlayPresentation()
 * - prefersReducedMotion()
 */

import { isAuthor } from "./aem";

/**
 * JS equivalent of CSS `@supports (property: value) {...}`
 *
 * @param {String} property
 * @param {Number|String} value
 *
 * @returns {Boolean}
 */
export function cssSupports(property, value) {
    // Handle browsers that don't understand @supports CSS, e.g. IE11
    if (!Object.prototype.hasOwnProperty.call(window, "CSS") || typeof window.CSS.supports !== "function") {
        return false;
    }

    return window.CSS.supports(property, value);
}

/**
 * Is the current page is an iframe presented within a modal overlay?
 */
export function isOverlay() {
    if (isAuthor() || isWindowTop()) {
        return false;
    }
    return document.querySelector("body").getAttribute("data-iframe") === "overlay";
}

/**
 * Find out if current window is top.
 * Useful in handling `iframe`s
 */
export function isWindowTop() {
    return window.top === window;
}

/**
 * Check browser support for `MediaQueryList.onchange` event
 * Event fires when registered breakpoint switches occur
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/onchange
 *
 * @returns {Boolean}
 */
export function matchMediaOnChange() {
    return window.matchMedia("all").onchange !== undefined;
}

/**
 * Add a page identifier for pages being presented in modal overlay
 * - `data`-attribute for JavaScript hook
 */
export function overlayPresentation() {
    const body = document.querySelector("body");
    if (!isAuthor() && !isWindowTop()) {
        body.setAttribute("data-iframe", "overlay");
    }
}

/**
 * JS equivalent of CSS `@media (prefers-reduced-motion: reduce) {...}`
 * Check if visitor's browser settings indicate a preference for reduced motion (A11y).
 *
 * @returns {Boolean}
 *
 * @note:
 * If browser doesn't recognize `prefers-reduced-motion` setting, we assume
 * no movement preferred (as a precaution). However, this means we always
 * return `true` on:
 * -- Edge (non-Chromium versions <= 45)
 * -- IE11
 * -- Samsung Internet
 *
 * For more information on `prefers-reduced-motion`, visit Mozilla Developer Network
 * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
export function prefersReducedMotion() {
    return !window.matchMedia("(prefers-reduced-motion: no-preference)").matches;
}

export default {
    cssSupports,
    isOverlay,
    isWindowTop,
    matchMediaOnChange,
    overlayPresentation,
    prefersReducedMotion
};
