/**
 * Common fund filtering options,
 * e.g Strategy group, Fund ID, Date from, Date to...
 *
 * Used by components:
 * 1). Price & Performance
 * 2). Documents & Factsheets
 * 3). Dividends (Fund distribution)
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { isAuthor } from "../../../../global/js/utils/aem";
import { deDuplicate } from "../../../../global/js/utils/array";
import { nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";

const FORM = document.querySelector("form.form-fund-data");

const SELECTORS = {
    GROUP: "data-group",
    FUND: "data-fund",
    DATE: "data-date",
    DOCTYPE: "data-doctype",
    PANEL_CONTAINER_MANUAL: "div[data-panel-container-manual]",
    PANEL_PARENT_SECTION: "section.panel-container",
    PANEL_MUTLI_SELECTOR: ".panel.panel--multiselector",
    NON_JAVASCRIPT_HIDDEN: "nonjavascript-hidden"
};

const ELEMENTS = {};

/**
 * Helper function to collect re-usable element references
 */
function updateElements() {
    // Form elements
    ELEMENTS.FIELDS_YEARS = nodeListArray(FORM.querySelectorAll(`#dateFromYear, #dateToYear`));
    ELEMENTS.FIELDS_MONTHS = nodeListArray(FORM.querySelectorAll(`#dateFromMonth, #dateToMonth`));
    ELEMENTS.FIELDS_DAYS = nodeListArray(FORM.querySelectorAll(`#dateFromDay, #dateToDay`));

    // Page content elements
    ELEMENTS.STRATEGIES = nodeListArray(FORM.querySelectorAll(`[${SELECTORS.GROUP}]`));
    ELEMENTS.FUNDS = nodeListArray(FORM.querySelectorAll(`[${SELECTORS.FUND}]`));
    ELEMENTS.DATES = nodeListArray(FORM.querySelectorAll(`[${SELECTORS.DATE}]`));
    ELEMENTS.DOCUMENTS = nodeListArray(FORM.querySelectorAll(`[${SELECTORS.DOCTYPE}]`));
    ELEMENTS.PANEL_CONTAINER_LIST = nodeListArray(document.querySelectorAll(SELECTORS.PANEL_CONTAINER_MANUAL));
    ELEMENTS.DOCUMENTS_DYNAMIC_PANEL = document.querySelector(SELECTORS.PANEL_MUTLI_SELECTOR);
}

/**
 * Filter-out page elements that to do not match strategy choice.
 * (Filtering determined by attached strategy data-attribute).
 * @param   {Array}  elements
 * @param   {Object}  data      -- Filtering options
 * @return  {Array} -- Array of elements that do not match strategy choice
 */
function filterItemsByStrategy(elements, data = {}) {
    const { strategy } = data;
    return elements.filter((element) => strategy && element.getAttribute(SELECTORS.GROUP) !== strategy);
}

/**
 * Filter-out page elements that to do not match fund choice.
 * (Filtering determined by attached fund ID data-attribute).
 * @param   {Array}  elements
 * @param   {Object}  data      -- Filtering options
 * @return  {Array} -- Array of elements that do not match fund choice
 */
function filterItemsByFund(elements, data = {}) {
    const { fund } = data;
    return elements.filter((element) => fund && hideFundData(element, fund));
}

/**
 * Checks if fund data has to hide.
 * (Filtering determined by attached fund ID data-attribute).
 * @param   {String}  fund
 * @param   {Object}  element
 * @return  {Boolean} -- true if fund data has to hide
 */
function hideFundData(element, fund) {
    let hideFund = true;
    const fundAttrValue = element.getAttribute(SELECTORS.FUND);
    if (fundAttrValue !== null && fundAttrValue !== undefined) {
        const funds = fundAttrValue.split(",");
        hideFund = !funds.includes(fund);
    }
    return hideFund;
}

/**
 * Filter-out page elements that to do not match dateFrom/dateTo choice.
 * (Filtering determined by attached date data-attribute).
 * @param   {Array}  elements
 * @param   {Object}  data      -- Filtering options
 * @return  {Array} -- Array of elements that do not match date range
 */
function filterByDates(elements, data = {}) {
    const { dateFrom, dateTo } = data;
    return elements.filter((element) => {
        const elementDate = new Date(element.getAttribute(SELECTORS.DATE));
        return dateFrom && dateTo && !(elementDate >= dateFrom && elementDate <= dateTo);
    });
}

/**
 * Filter-out page elements that to do not match document-type choice.
 * (Filtering determined by attached doctype data-attribute).
 * @param   {Array}  elements
 * @param   {Object}  data      -- Filtering options
 * @return  {Array} -- Array of elements that do not match date range
 */
function filterByDocType(elements, data = {}) {
    const { doctype } = data;

    if (doctype !== undefined && isElement(ELEMENTS.DOCUMENTS_DYNAMIC_PANEL)) {
        if (doctype.includes("-manual-doctype")) {
            ELEMENTS.DOCUMENTS_DYNAMIC_PANEL.classList.remove(SELECTORS.NON_JAVASCRIPT_HIDDEN);
            ELEMENTS.DOCUMENTS_DYNAMIC_PANEL.hidden = true;
            ELEMENTS.PANEL_CONTAINER_LIST.forEach((panel) => {
                const panelParentSection = panel.closest(SELECTORS.PANEL_PARENT_SECTION);
                if (panel.id === doctype && isElement(panelParentSection)) {
                    panelParentSection.hidden = false;
                } else {
                    panelParentSection.hidden = true;
                }
            });
            return [];
        } else {
            ELEMENTS.DOCUMENTS_DYNAMIC_PANEL.classList.add(SELECTORS.NON_JAVASCRIPT_HIDDEN);
            ELEMENTS.DOCUMENTS_DYNAMIC_PANEL.hidden = false;
            hideAllManualPanels();
            return elements.filter((element) => doctype && element.getAttribute(SELECTORS.DOCTYPE) !== doctype);
        }
    }
    return [];
}

/**
 * Event listener:
 * Resets the FUND `select` box whenever STRATEGY group is changed
 */
function resetFundOnStrategyChange() {
    const FIELD_STRATEGY = FORM.querySelector("#strategy-field");
    const FIELD_FUND = FORM.querySelector("#fund-field");
    if (!isElement(FIELD_STRATEGY) || !isElement(FIELD_FUND)) {
        return;
    }
    FIELD_STRATEGY.addEventListener("change", (e) => (FIELD_FUND.value = ""), false);
}

/**
 * Form Helper:
 * Switch a form field's disabled status.
 * Reset the field value.
 * @param   {Element}  field
 * @param   {Boolean}  value
 */
function setDisabledStatus(field, value) {
    if (isElement(field)) {
        field.disabled = !value;
        field.value = "";
    }
}

/**
 * Date/Form Helper:
 * Update the days field, once a month is chosen.
 * Avoid showing impossible dates, e.g. 31 Feb!
 * @param   {Element}  monthField -- Which month field (e.g. From or To?)
 * @param   {Element}  dayField -- Which day field (e.g. From or To?)
 * @param   {String}  value -- Month field value
 */
function setMonthDays(monthField, dayField, value) {
    const OPTIONS = nodeListArray(dayField.querySelectorAll("option"));
    const days = monthField.querySelector(`[value="${value}"]`).getAttribute("data-days");
    OPTIONS.forEach((option) => {
        const hiddenStatus = value && option.getAttribute("value") > Number(days);
        option.hidden = hiddenStatus;
        option.disabled = hiddenStatus;
        // ^^ Safari Fix:
        // Not possible to hide an option-tag on Safari's UI layer;
        // adding disabled status as lowest impact solution.
    });
}

/**
 * Date/Form Helper:
 * Reset the date fields
 * @param   {Array}  filters -- Array of form field elements
 */
function resetDateFilters(filters) {
    const DATE_YEARS = [...ELEMENTS.FIELDS_YEARS];
    const DATE_REST = [...ELEMENTS.FIELDS_MONTHS, ...ELEMENTS.FIELDS_DAYS];
    DATE_YEARS.forEach((field) => (field.value = ""));
    DATE_REST.forEach((field) => setDisabledStatus(field));
}

/**
 * Helper:
 * Run through an array of elements, setting their hidden status.
 * @param   {Array}  elements
 * @param   {Boolean}  hiddenStatus
 * @return  {Array}
 */
function applyStatusToAll(elements, hiddenStatus = false) {
    return deDuplicate(elements, hiddenStatus).forEach((element) => {
        const tagName = element.tagName.toUpperCase();
        element.hidden = hiddenStatus;

        switch (tagName) {
            case "OPTION":
                // Safari Fix:
                // Not possible to hide an option-tag on Safari's UI layer,
                // thus setting `disabled` status in addition to `hidden`;
                // low impact solution without risking invalidating previous
                // testing rounds for other browsers (i.e. those unaffected
                // by this issue).
                element.disabled = hiddenStatus;
                break;
            case "TR":
                // Hiding a table row requires a heavier-handed approach.
                // Additional class identifier may also be useful in other ways.
                element.classList.toggle("hide-row", element.hidden);
                break;
        }
    });
}

/**
 * Helper:
 * Run through an array of manual panel elements to hide.
 */
function hideAllManualPanels() {
   ELEMENTS.PANEL_CONTAINER_LIST.forEach((panel) => {
       const panelParentSection = panel.closest(SELECTORS.PANEL_PARENT_SECTION);
       if (isElement(panelParentSection)) {
           panelParentSection.hidden = true;
       }
   });
}

export default (function fundFieldPartial() {
    if (!isElement(FORM)) {
        return;
    }

    updateElements();
    resetFundOnStrategyChange();
    resetDateFilters();

    if(!isAuthor()){
        hideAllManualPanels();
    }

    ELEMENTS.FIELDS_YEARS.forEach((year, index) => {
        year.addEventListener(
            "change",
            (e) => {
                const { target } = e;
                setDisabledStatus(ELEMENTS.FIELDS_MONTHS[index], target.value);
                setDisabledStatus(ELEMENTS.FIELDS_DAYS[index], target.value);
            },
            false
        );
    });

    ELEMENTS.FIELDS_MONTHS.forEach((month, index) => {
        month.addEventListener(
            "change",
            (e) => {
                const { target } = e;
                setDisabledStatus(ELEMENTS.FIELDS_DAYS[index], target.value);
                setMonthDays(target, ELEMENTS.FIELDS_DAYS[index], target.value);
            },
            false
        );
    });

    window.addEventListener(
        EVENTS.MULTISELECTOR.OPTION_CHANGED,
        (e) => {
            const { DATES, DOCUMENTS, FUNDS, STRATEGIES } = ELEMENTS;
            const { data } = e.detail;
            const { dateFrom, dateTo, reset } = data;

            if (reset) {
                resetDateFilters();
            }

            // Reset all elements to visible.
            applyStatusToAll([...DATES, ...DOCUMENTS, ...FUNDS, ...STRATEGIES], false);
            // ...Gather the array of elements that don't match the filtering settings & hide them.
            applyStatusToAll(
                [
                    ...filterItemsByStrategy(STRATEGIES, data),
                    ...filterItemsByFund(FUNDS, data),
                    ...filterByDocType(DOCUMENTS, data)
                ],
                true
            );
        },
        false
    );
})();
