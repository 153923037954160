/**
 * DOM/Node API utilities
 * ======================
 * Index
 *
 * - Handling array-like objects,
 *   E.g. turning them into genuine arrays
 * -- classListArray(element)
 * -- htmlCollectionArray(elementList)
 * -- htmlStringToNodeArray(string)
 * -- nodeListArray(elementList)
 *
 * - Document/DOM status:
 * -- loadScript(attributes, callback)
 * -- ready(fn)
 */

/**
 *
 * Handling array-like objects
 * ---------------------------
 * E.g. turning them into genuine arrays for use with array methods
 *
 */

/**
 * Convert a DOM element array-like object into a true array
 * Array methods can then be used on them: e.g. forEach
 * More robust than `Array.prototype.forEach.call(elements, function (el, i) {...});`
 *
 * @param  {Object} arrayLikeObject
 * @param  {Instance} CollectionType
 * @return {Array}
 */
function convertToTrueArray(arrayLikeObject, CollectionType) {
    const elementArray = [];
    if (!(arrayLikeObject instanceof CollectionType)) {
        return elementArray;
    }
    for (let i = 0; i < arrayLikeObject.length; i += 1) {
        elementArray.push(arrayLikeObject[i]);
    }
    return elementArray;
}

/**
 * Covert DOM element's `classList` into a true array
 *
 * @param  {Element} element
 * @return {Array}
 */
export function classListArray(element) {
    if (!(element instanceof Element)) {
        return [];
    }

    return convertToTrueArray(element.classList, DOMTokenList);
}

/**
 * Convert DOM element HTMLCollection into a true array
 * e.g. `ParentNode.children`
 *
 * @param   {Object}  elementList
 * @return  {Array}
 */
export function htmlCollectionArray(elementList) {
    return convertToTrueArray(elementList, HTMLCollection);
}

/**
 * Covert an HTML string into an array of DOM nodes.
 * Especially useful for Element.append() calls
 * e.g.
 *  `ELEMENT.append(...htmlStringToNodeArray(HTML_STRING))`
 *
 * @param   {String}  htmlString
 * @return  {Array}
 */
export function htmlStringToNodeArray(htmlString) {
    const tmpDoc = new DOMParser().parseFromString(htmlString, "text/html");
    return nodeListArray(tmpDoc.body.childNodes);
}

/**
 * Convert a DOM element NodeList into a true array
 * Array methods can then be used on them: e.g. forEach
 * More robust than Array.prototype.forEach.call(elements, function (el, i) {...});
 * @param  {Object} nodeList
 * @return {Array}
 */
export function nodeListArray(elementList) {
    return convertToTrueArray(elementList, NodeList);
}

/**
 *
 * Document/DOM status
 * -------------------
 *
 */

/**
 * Load external JavaScript files
 * e.g. Google Maps or other platform APIs
 *
 * @param   {Object}  attributes
 * @return  {Element}
 */
export function loadScript(
    attributes = {},
    /* istanbul ignore next */
    callback = () => {}
) {
    const script = document.createElement("script");
    const attrs = {
        ...attributes,
        // As we are loading script dynamically, we'll enforce `async` & `defer` for non-blocking script execution
        async: "",
        defer: ""
    };
    Object.keys(attrs).map((key) => script.setAttribute(key, attrs[key]));
    script.onload = function ready() {
        callback();
    };
    document.body.appendChild(script);
    return script;
}

/**
 * DOM ready listener
 * e.g. The equivalent of jQuery.ready()
 *
 * @param   {Function}  fn
 */
export function ready(fn) {
    if (document.readyState !== "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export default {
    classListArray,
    htmlCollectionArray,
    htmlStringToNodeArray,
    loadScript,
    nodeListArray,
    ready
};
