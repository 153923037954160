/**
 * Template utility
 * ================
 * Methods to get or manipulate strings containing placeholder 'tokens'
 * demarcated via double curly braces (e.g. `{{PROPERTY_NAME}}`) against a
 * data object.
 *
 * Index
 *
 * - getTemplate(selector)
 * - replacePlaceholders(templateString, data)
 * - Template(templateSelector, data) -- Default export
 */

/**
 * Get the template's string content
 *
 * @param {String} selector
 * @return {String|Null}
 */
export function getTemplate(selector) {
    const template = document.querySelector(`${selector}[type="text/template"]`);
    return template ? template.innerHTML.trim() : template;
}

/**
 * Find/replace placeholder strings `{{PROPERTY_NAME}}` with PROPERTY_VALUE
 *
 * @param {String} templateString
 * @param {Object} data
 * @return {String}
 */
export function replacePlaceholders(templateString, data = {}) {
    Object.keys(data).forEach((key) => {
        const pattern = new RegExp(`{{\\s*${key}\\s*}}`, "g");
        templateString = templateString.replace(pattern, data[key]);
    });
    return templateString;
}

/**
 * Main API exposure
 *
 * @param {String} templateSelector
 * @param {Object} data
 * @return {String|Undefined}
 */
export default function Template(templateSelector, data = {}) {
    const template = getTemplate(templateSelector);

    if (!template) {
        return;
    }

    return replacePlaceholders(template, data);
}
