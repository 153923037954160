// Constants
import EVENTS from "../../../../global/js/constants/events";

// APIs
import Cookies from "../../../../global/js/api/cookies";

// Utils
import { getPageTemplate, isAuthor } from "../../../../global/js/utils/aem";
import { isElement } from "../../../../global/js/utils/element";

const BANNER = document.querySelector("#cookie-banner");
const COOKIE_NAME = `FSSA-ALLOW-COOKIES`;

function removeBanner() {
    return BANNER.parentNode.removeChild(BANNER);
}

function dispatchEvent() {
    window.dispatchEvent(
        new CustomEvent(EVENTS.COOKIES_NOTIFICATION.COOKIES_ACCEPTED, {
            detail: {}
        })
    );
}

function addButtonEvents() {
    BANNER.addEventListener(
        "click",
        (e) => {
            const BTN = e.target.closest("button");

            if (!BTN) {
                return;
            }

            const ACCEPTED = BTN.getAttribute("data-cookies").toUpperCase() === "ACCEPT";
            const options = {}; // Left empty = Session cookie

            if (ACCEPTED) {
                options["max-age"] = 2592000; // Expires at 30 days
                dispatchEvent();
            }

            Cookies.set(COOKIE_NAME, ACCEPTED, options);
            removeBanner();
        },
        false
    );
}

export default (function cookieNotification() {
    // Exit early conditions...
    // (inc. making sure component is always visible from the home page template
    // in author mode; for editing)
    if (!isElement(BANNER) || (isAuthor() && getPageTemplate() === "HOME")) {
        return;
    }

    const BANNER_SECTION = BANNER.querySelector("section");
    const COOKIE_VALUE = Cookies.get(COOKIE_NAME);

    if (COOKIE_VALUE || !isElement(BANNER_SECTION)) {
        removeBanner();
        if (COOKIE_VALUE.toUpperCase() === "TRUE") {
            dispatchEvent();
        }
        return;
    }

    // Complete banner set-up ...
    BANNER_SECTION.hidden = false;
    addButtonEvents();
})();
