/**
 * Event utilities
 * ===============
 * Index
 *
 * - debounce(FUNCTION, WAIT_TIME, CALL_IMMEDIATELY)
 * - throttle(FUNCTION, WAIT_TIME)
 *
 */

export function debounce(fn, wait = 250, immediate) {
    let timeout;
    return function db(...args) {
        const context = this;
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            if (!immediate) {
                fn.apply(context, args);
            }
        }, wait);
        if (callNow) {
            fn.apply(context, args);
        }
    };
}

export function throttle(fn, wait = 250) {
    let inThrottle;
    return function th(...args) {
        const context = this;
        if (!inThrottle) {
            inThrottle = true;
            fn.apply(context, args);
            setTimeout(() => {
                inThrottle = false;
            }, wait);
        }
    };
}

export default {
    debounce,
    throttle
};
