// APIs
import Modal from "../../../../global/js/api/modal";

// Utils
import { nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";

export default (function initCaseStudyTeaser() {
    const caseStudyTeasers = nodeListArray(document.querySelectorAll(".casestudy"));

    if (caseStudyTeasers.length < 1) {
        return;
    }

    caseStudyTeasers.forEach((study) => {
        const link = study.querySelector("a.casestudy-link");

        if (!isElement(link)) {
            return;
        }

        const studyModal = Modal({
            trigger: link,
            type: "iframe",
            url: link.getAttribute("href"),
            additionalCss: ["modal--casestudy"],
            closeIcon: "collapse"
        });

        link.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                studyModal.open();
            },
            false
        );
    });
})();
