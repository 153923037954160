/**
 * Background Component (video autoplay)
 * =====================================
 * Script is aimed at the background component used in the Case Study Template pages.
 * 1). Looks for `<video>` tags in page that include the autoplay data attribute.
 * 2). Checks visitor's browser/OS is NOT set to `prefers-reduced-motion`
 * 3). Add `autoplay` attribute to video
 */

import { nodeListArray } from "../../../../global/js/utils/dom";
import { prefersReducedMotion } from "../../../../global/js/utils/window";

const videos = nodeListArray(document.querySelectorAll(`[data-video="autoplay"]`));

function autoPlay(video) {
    // Wrapped in zero timeout delay to prevent iOS Safari throwing aborted promise error
    setTimeout(() => {
        video.setAttribute("autoplay", "");
        video.play();
    }, 0);
}

export default (function initBackground() {
    if (prefersReducedMotion()) {
        return;
    }

    videos.forEach((video) => {
        autoPlay(video);
    });
})();
