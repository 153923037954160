/**
 * Tab-trap API
 * ============
 * Hold keyboard focus within a specified element area, preventing access to elements
 * outside its scope.
 *
 * An Accessibility (A11y) enhancement that matches experience between keyboard/switch
 * and mouse page navigation where access is deliberately restricted to an element until an
 * expected action is made by the site visitor.
 *
 * For example, in modal overlays a site visitor is not allowed to access elements
 * behind/outside the overlay until it has been closed. Tab-trap ensures that visitors
 * navigating by keyboard/switch will not be able to reach focusable elements outside
 * the overlay (something they would be able to do without tab-trapping functionality).
 *
 * Usage
 * -----
 * ```
 * const restrictTabbingToElement = tabTrap(ELEMENT_SELECTOR_STRING);
 * // Remove focus restriction
 * restrictTabbingToElement.remove();
 * ```
 */

// Utils
import { nodeListArray } from "../utils/dom";
import { focusableElements, isElement } from "../utils/element";

const stops = {};

function detectTabKeyPress(e) {
    const { first, last } = stops;

    function jumpTabStop(watchFor, jumpTo) {
        if (document.activeElement === watchFor) {
            e.preventDefault();
            jumpTo.focus();
        }
    }

    // Detect only tab key code
    if (e.key.toUpperCase() !== "TAB") {
        return () => {};
    }

    // If shift key detected, user moving backwards
    return e.shiftKey ? jumpTabStop(first, last) : jumpTabStop(last, first);
}

function init() {
    const tabStops = nodeListArray(this.querySelectorAll(focusableElements)).filter((focusable) => !focusable.hidden);

    this.setAttribute("tabindex", -1);

    if (tabStops.length > 0) {
        stops.first = tabStops[0];
        stops.last = tabStops[tabStops.length - 1];
    }

    this.focus();
    this.addEventListener("keydown", detectTabKeyPress, false);
}

export default function tabTrap(selector) {
    const target = document.querySelector(selector);

    if (!isElement(target)) {
        return {};
    }

    init.call(target);

    return {
        remove: () => {
            target.removeEventListener("keydown", detectTabKeyPress, false);
        }
    };
}
