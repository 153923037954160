import A11y from "./a11y";
import { createElement } from "./element";
import { prefersReducedMotion } from "./window";

function getPanelElements(element) {
    const { control, target } = A11y.getControls(element);
    const targetParent = target.parentElement || createElement("div");

    return {
        control,
        target,
        targetParent
    };
}

export function open(element, options = {}) {
    const { control, target, targetParent } = getPanelElements(element);
    const { immediate } = options;

    const openTarget = () => {
        target.classList.remove("closed");
        target.classList.add("open");
        targetParent.setAttribute("data-panel", "open");

        if (prefersReducedMotion() || immediate) {
            target.style.setProperty("--expand_max", "none");
        }
    };

    A11y.setExpanded(control);
    target.hidden = false;
    target.style.setProperty("--expand_max", `${target.scrollHeight}px`);

    if (immediate) {
        return openTarget();
    }

    window.requestAnimationFrame(() => openTarget());
}

function reset(element) {
    element.hidden = true;
    element.style.removeProperty("--expand_max");
}

export function close(element, options = {}) {
    const { control, target, targetParent } = getPanelElements(element);
    const { immediate } = options;

    if (immediate) {
        target.hidden = true;
    }

    target.style.setProperty("--expand_max", `${target.scrollHeight}px`);

    setTimeout(() => {
        A11y.setCollapsed(control);
        target.classList.remove("open");
        target.classList.add("closed");

        if (targetParent) {
            targetParent.removeAttribute("data-panel");
        }

        if (prefersReducedMotion()) {
            reset(target);
        }
    }, 0);
}

export function toggle(trigger) {
    if (trigger.getAttribute("aria-expanded") === "true") {
        return close(trigger);
    }
    return open(trigger);
}

export function transitionEnd(element) {
    function expandEndEvent(e) {
        const { propertyName, target } = e;
        const { control } = A11y.getControls(element);
        const EXPANDED = control.getAttribute("aria-expanded");

        if (this !== target) {
            return;
        }

        // Only interested in max-height transition; specifically target behaviour to that.
        if (propertyName === "max-height") {
            e.stopPropagation();

            // Panel completed closing action
            if (EXPANDED === "false") {
                reset(target);
                return;
            }

            // Panel completed opening action
            // Set max-height to `none` at the end of the opening animation
            // caters for lazy/late-loading contents; avoiding the edge-case
            // where dynamic content risks getting cut-off by a specifically
            // calculated pixel height value.
            target.style.setProperty("--expand_max", "none");
        }
    }

    element.addEventListener("transitionend", expandEndEvent.bind(element), false);
    element.addEventListener("transitioncancel", expandEndEvent.bind(element), false);
}

export default (function Panel() {
    return {
        open,
        close,
        toggle,
        transitionEnd
    };
})();
