/**
 * Cookies API
 * ===========
 * API exposed on project namespace as `FSSA.cookie` for convenient access
 * during testing/QA
 *
 * Index (API method / internal function name)
 *
 * - Cookies.data() / getCookieData()
 * - Cookies.expire(name) / expireCookie(name)
 * - Cookies.get(name) / getCookieByName(name)
 * - Cookies.set(name, value [, attributes]) / setCookie(name, value [, attributes])
 *
 */

export function getCookieData() {
    const cookies = document.cookie.split(";");
    return cookies.reduce((collection, ck) => {
        const [key, value] = ck.split("=");
        collection[key.trim()] = value;
        return collection;
    }, {});
}

export function expireCookie(name) {
    return setCookie(name, null, {
        "max-age": 0
    });
}

export function getCookie(name) {
    const data = getCookieData();
    if (Object.prototype.hasOwnProperty.call(data, String(name))) {
        return decodeURIComponent(data[String(name)]);
    }
    return "";
}

export function setCookie(name, value, attributes = {}) {
    const attrs = { path: "/", SameSite: "lax", ...attributes };
    // Flatten attributes into a semi-colon delimited string
    const additionalSettings = Object.keys(attrs)
        .map((attr) => {
            return `${attr}=${attrs[attr]}`;
        })
        .join(";");

    document.cookie = `${name.trim()}=${encodeURIComponent(value)};${additionalSettings}`;
}

export default (function Cookies() {
    return {
        data: getCookieData,
        expire: expireCookie,
        get: getCookie,
        set: setCookie
    };
})();
