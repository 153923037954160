// APIs:
import Cookies from "../../../../global/js/api/cookies";

// Utils
import { isElement } from "../../../../global/js/utils/element";

const cookieName = 'importantNotice';
const notesExpandedClass = 'important-note-expanded';
const notesCollapsedClass = 'important-note-collapsed';
const hideClass = 'important-note-hide';
const showClass = 'important-note-show';
const notesContentEle = document.querySelector('.fsi-important-note__accordion-wrapper');
const importantNoteCollapser = document.querySelector('.fsi-important-note__title-2');
const importantNoteExpander = document.querySelector('.fsi-important-note__title-1');
const buttonExpander = document.querySelector('.important-note-vertical-icon');
const country = document.querySelector('.country-code');
const iconClicked = 'accordion__price-performance-vertical-icon-clicked';

const collapseAction = () => {
	if(!Cookies.get(cookieName)) {
		Cookies.set(cookieName, 'Important-Note');
	}
	notesContentEle.classList.add(hideClass, notesCollapsedClass);
	notesContentEle.classList.remove(notesExpandedClass);
	importantNoteExpander.classList.remove(hideClass);
	importantNoteExpander.classList.add(showClass);
	importantNoteCollapser.classList.add(hideClass);
	buttonExpander.classList.remove(iconClicked);
}

const expandAction = () => {
	notesContentEle.classList.remove(hideClass, notesCollapsedClass);
	notesContentEle.classList.add(notesExpandedClass);
	importantNoteCollapser.classList.remove(hideClass);
	importantNoteExpander.classList.add(hideClass);
	importantNoteExpander.classList.remove(showClass);
	buttonExpander.classList.add(iconClicked);
}

if (importantNoteCollapser !== null && importantNoteCollapser !== undefined &&
    importantNoteExpander !== null && importantNoteExpander !== undefined) {

    importantNoteCollapser.addEventListener('click', collapseAction);
    importantNoteExpander.addEventListener('click', expandAction);

    document.querySelector('.fsi-important-note__button-expand').addEventListener('click', () => {
        const availableClassses = document.querySelector('.important-note-vertical-icon').classList;
        if (availableClassses.contains(iconClicked)) {
            collapseAction();
        } else {
            expandAction();
        }
    });
}