/**
 * Parallax effect on hero image/page scroll
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { debounce } from "../../../../global/js/utils/event";
import { isElement } from "../../../../global/js/utils/element";
import { prefersReducedMotion } from "../../../../global/js/utils/window";

const hero = document.querySelector(".hero");
const heroImg = document.querySelector(".hero-image > img");
const parallaxSpeed = 0.4;

let heroHeights = {};

const handleResize = debounce((event) => {
    getHeroHeights();
    setTranslateY(0);
});

function getHeroHeights() {
    const heroHeight = hero.offsetHeight;
    const heroImgHeight = heroImg.offsetHeight;
    const startingPosition = (heroImgHeight - heroHeight) * -1;

    heroHeights = {
        hero: heroHeight,
        img: heroImgHeight,
        startingPosition
    };

    return heroHeights;
}

function setTranslateY(yValue) {
    heroImg.style.transform = `translateY(${yValue}px)`;
}

function handleScroll() {
    return requestAnimationFrame(() => {
        const { startingPosition } = heroHeights;
        const adjustment = startingPosition + window.scrollY * parallaxSpeed;

        if (adjustment > 0) {
            return;
        }

        return setTranslateY(adjustment);
    });
}

function handleViewportSwitch(e) {
    const { matches } = e.detail;
    heroImg.setAttribute("data-parallax", matches ? "active" : "inactive");
    return parallax(matches ? "add" : "remove");
}

function parallax(status = "add") {
    const listenerType = `${status}EventListener`;

    if (status === "add") {
        getHeroHeights();
        // Set image position, so image & hero bottom edges align
        setTranslateY(heroHeights.startingPosition);
    }

    window[listenerType]("scroll", handleScroll, false);
    window[listenerType]("resize", handleResize, false);
}

export default (function initHero() {
    if (prefersReducedMotion() || !isElement(heroImg)) {
        return;
    }

    // Only play parallax when hero component is in view
    new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) {
            parallax("remove");
            return;
        }

        if (heroImg.getAttribute("data-parallax") === "active") {
            parallax("add");
        }
    }).observe(hero);

    window.addEventListener(EVENTS.VIEWPORT_WATCHER.DESKTOP_SWITCH, handleViewportSwitch, false);
})();
