/**
 * De-duplicate an array of items.
 * @param {Array}
 * @return  {Array}  - Return an array of unique values
 */
export function deDuplicate(arrayInput) {
    return arrayInput.filter((value, index, array) => {
        return array.indexOf(value) === index;
    });
}

export default { deDuplicate };
