/**
 * Dividends Form Component
 * ==================================
 * Most component script can be found in file:
 *
 * -- `form/common/scripts/multiselect.js`
 * -- `form/common/scripts/fundfield.js`
 *
 * These files are shared by components:
 *
 * 1). Price & Performance
 * 2). Documents & Factsheets
 * 3). Dividends (Fund distribution)
 *
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import {
    htmlStringToNodeArray,
    nodeListArray
} from "../../../../global/js/utils/dom";
import {
    isElement
} from "../../../../global/js/utils/element";

import {
    isDate
} from "../../common/scripts/datefields";

const FORM = document.querySelector("form.dividends-form");

const SELECTORS = {
    ROWCOUNT: "data-rowcount",
    TABLE_PARENT_DIV: ".table.table--data.table--scrollable",
    GO_BTN: ".js-dividend-btn .btn-submit",
    RESET_BTN: ".multi-selector--cta .btn-reset",
    HISTORICAL_DIVIDEND: ".dividend-historical-data",
    DIVIDEND_PANEL: ".panel-inset"
};

const ELEMENTS = {};
let multiSelectorData = {};

const goButton = document.querySelector(SELECTORS.GO_BTN);
const RESET_BTNS = nodeListArray(document.querySelectorAll(SELECTORS.RESET_BTN));
const historicalDividendWrapperElement = document.querySelector(SELECTORS.HISTORICAL_DIVIDEND);
const dividendPanel = document.querySelector(SELECTORS.DIVIDEND_PANEL);

function clearResults(show) {
    dividendPanel.hidden = show;
    historicalDividendWrapperElement.innerHTML = "";
}

function appendDatesToQueryString(url, dateFrom, dateTo, strategy, fund) {
    const [path, queryString] = url.split("?");
    const params = new URLSearchParams(queryString);
    const yearFrom = isDate(dateFrom).getFullYear();
    const yearTo = isDate(dateTo).getFullYear();

    params.set("dateFrom", yearFrom);
    params.set("dateTo", yearTo);
    params.set("strategyId", strategy);
    params.set("fundId", fund);
    return `${path}?${params.toString()}`;
}

function fetchAndShowHistoricalDividendData() {
    const {
        fund,
        strategy,
        dateFrom,
        dateTo
    } = multiSelectorData;

    if (!isElement(historicalDividendWrapperElement)) {
        return;
    }

    clearResults(true);

    const historicalDividendEndpoint = FORM.dataset.historicalDividendEndpoint;
    if (historicalDividendEndpoint) {
        const noResultsMessage = FORM.dataset.messageNoResults;

        getHistoricalPriceData(appendDatesToQueryString(historicalDividendEndpoint, dateFrom, dateTo, strategy, fund))
            .then((text) => {
                if (!text) {
                    historicalDividendWrapperElement.innerHTML = `<p>${noResultsMessage}</p>`;
                    return;
                }

                historicalDividendWrapperElement.innerHTML = text;
            })
            .catch((error) => {
                console.warn(`Fetch operation error: ${error.message}`);
            });
    }
}

async function getHistoricalPriceData(priceUrl) {
    const response = await fetch(priceUrl);
    if (!response.ok) {
        throw new Error(`HTTP error status: ${response.status}`);
    }
    return await response.text();
}

function validateForm(data = {}) {
    const FORM_ERRORS = FORM.querySelector("div.error");
    const {
        dateFrom,
        dateTo,
        strategy,
        fund
    } = data;
    const dateValid = dateTo > dateFrom;

    function addError(validityTest, key) {
        if (!validityTest) {
            FORM_ERRORS.append(...htmlStringToNodeArray(`<p>${FORM.dataset[key]}</p>`));
        }
    }

    FORM_ERRORS.textContent = "";

    if (fund && strategy && dateValid) {
        return true;
    }

    addError(strategy, "emptyStrategyMessage");
    addError(fund, "emptyFundMessage");
    addError(dateValid, "dateMessage");
    return false;
}

function hideNoResultTables() {
    const {
        fund
    } = multiSelectorData;
    if (fund) {
        ELEMENTS.TABLES.forEach((table) => {
            const ROWS_MAX = Number(table.getAttribute(SELECTORS.ROWCOUNT) || 0);
            const ROWS_HIDDEN = table.querySelectorAll("tbody > tr[hidden]").length;
            const RESULT = ROWS_MAX - 1 > ROWS_HIDDEN;

            const tableParentDiv = table.closest(SELECTORS.TABLE_PARENT_DIV);
            if (isElement(tableParentDiv)) {
                tableParentDiv.hidden = !RESULT;
            }
        });
    }
}

export default (function dividendsForm() {
    if (!isElement(FORM)) {
        return;
    }

    if (isElement(goButton)) {
        goButton.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                if (validateForm(multiSelectorData)) {
                    fetchAndShowHistoricalDividendData();
                }
            },
            false
        );
    }

    RESET_BTNS.forEach((reset) => {
        reset.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                clearResults(false);
            },
            false
        );
    });

    ELEMENTS.TABLES = nodeListArray(FORM.querySelectorAll(`table[${SELECTORS.ROWCOUNT}]`));
    window.addEventListener(
        EVENTS.MULTISELECTOR.OPTION_CHANGED,
        (e) => {
            const {
                data
            } = e.detail;
            multiSelectorData = data;
            hideNoResultTables();
        },
        false
    );
})();