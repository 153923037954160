/**
 * Custom Event placed on window
 * Announces when there is a mobile/tablet/desktop switch in viewport width.
 * This utility is used by:
 * 1). Site navigation
 * 2). Hero parallax
 */

// Constants
import EVENTS from "../constants/events";

const BREAKPOINTS = {
    TABLET: "48em",
    DESKTOP: "62em"
};

function setBreakpointWatch(breakpoint, callback = () => {}) {
    const mql = window.matchMedia(`(min-width: ${BREAKPOINTS[breakpoint]})`);

    if (!BREAKPOINTS[breakpoint]) {
        return;
    }

    callback(mql);
    try {
        mql.addEventListener("change", (e) => callback(e), false);
    } catch {
        mql.addListener((e) => callback(e), false);
    }
}

function desktopSwitch() {
    return setBreakpointWatch("DESKTOP", (watcher) => {
        window.dispatchEvent(
            new CustomEvent(EVENTS.VIEWPORT_WATCHER.DESKTOP_SWITCH, {
                detail: watcher
            })
        );
    });
}

function tabletSwitch() {
    return setBreakpointWatch("TABLET", (watcher) => {
        window.dispatchEvent(
            new CustomEvent(EVENTS.VIEWPORT_WATCHER.TABLET_SWITCH, {
                detail: watcher
            })
        );
    });
}

export default function viewportWatcher() {
    tabletSwitch();
    desktopSwitch();
}
