/**
 * AEM utilities
 * =============
 * Index
 *
 * - checkAndRedirectToVanityUrl()
 * - getVanityUrlsList()
 * - getRedirectToPath()
 */

/**
* Function to form the gate redirect path
* @return  {String}
*/
export function getRedirectToPath(destination, urlParams, addHash) {
    let redirectToPath = `${location.origin}${destination}`;
    if (urlParams.toString()) {
        redirectToPath = redirectToPath + "?" + decodeURIComponent(urlParams);
    }
    if (addHash) {
        redirectToPath = redirectToPath + location.hash;
    }
    return redirectToPath;
}

/**
 * Function to check if a vanity URL exists or not.
 * If exists then redirect to the vanity URL else redirect to the country-language-audience home page.
 */
export function checkAndRedirectToVanityUrl(redirectToVanityPath, destination, urlParams) {
	getVanityUrlsList()
		.then((vanityUrlsList) => {
			if (vanityUrlsList.includes(redirectToVanityPath)) {
				window.location = getRedirectToPath(redirectToVanityPath, urlParams, true);
			} else {
				urlParams.set('show_no_vanity_message', true);
				window.location = getRedirectToPath(destination, urlParams, false);
			}
		})
		.catch((error) => {
			console.warn(`Fetch operation error: ${error.message}`);
		});
}

/**
 * Function to make a call to the vanity URLs HTML API to get the list of vanity's configured in AEM instance.
 */
export async function getVanityUrlsList() {
	const response = await fetch("/libs/granite/dispatcher/content/vanityUrls.html");
	if (!response.ok) {
		throw new Error(`HTTP error status: ${response.status}`);
	}
	return await response.text();
}

export default {
    getVanityUrlsList,
    checkAndRedirectToVanityUrl,
    getRedirectToPath
};
