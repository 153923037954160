/**
 * Global/Base JavaScript
 * ======================
 * This JavaScript file is imported by BOTH `entry.es6.js` and `entry.es5.js`
 */

// ** Utilities
// * ----------
// *
import Cookies from "./api/cookies";
import CookiesTerms from "./api/cookiesSiteTerms";
import { ready } from "./utils/dom";
import { overlayPresentation, prefersReducedMotion } from "./utils/window";

// ** Project-wide features
// * ----------------------
// *
import safeLinks from "./base/safeLinks";
import viewportWatcher from "./base/viewportWatcher";

// ** Component imports
// * ------------------
// *
import "../../components/structure/navigation/scripts/navigation";
import "../../components/structure/cookies/scripts/cookie-notification";

import "../../components/content/casestudy/scripts/casestudy";
import "../../components/content/card/scripts/card-animate";
import "../../components/content/card/scripts/card-profile";
import "../../components/content/readmore/scripts/readmore";
import "../../components/content/table/scripts/table";

import "../../components/form/common/scripts/fundField";
import "../../components/form/common/scripts/multiselect";
import "../../components/form/documents/scripts/documents";
import "../../components/form/priceperformance/scripts/priceperformance";
import "../../components/form/dividends/scripts/dividends";
import "../../components/form/strategy-filter/scripts/strategy-filter";

import "../../components/structure/accordion/scripts/accordion";
import "../../components/structure/background/scripts/background";
import "../../components/structure/hero/scripts/hero";
import "../../components/structure/tabs/scripts/tabs";
import "../../components/structure/timeline/scripts/timeline";
import "../../components/structure/jumplinks/scripts/jumplinks";
import "../../components/structure/strategyfilter/scripts/strategyfilter";

// Country Selector/Site Disclaimer/Region Link group
import "../../components/form/sitedisclaimer/scripts/sitedisclaimer";
import "../../components/structure/countryselector/scripts/countryselector";
import "../../components/structure/navigation/scripts/global-redirect";
import "../../components/structure/navigation/scripts/region-link";
import "../../components/structure/important-note/scripts/important-note";

//OneTrust Consent Banner Script
import "../../components/structure/onetrustconsentbanner/scripts/onetrustconsentbanner";

ready(() => {
    overlayPresentation();
    safeLinks();
    viewportWatcher();

    // Expose useful utilities on the project namespace, so Devs & QA can
    // quickly assess/affect environment
    window.FSSA.cookies = Cookies;
    window.FSSA.cookies.terms = CookiesTerms;
    window.FSSA.prefersReducedMotion = prefersReducedMotion();

    console.info("Browser experience: ", {
        prefersReducedMotion: FSSA.prefersReducedMotion
    });
});
