// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { isAuthor } from "../../../../global/js/utils/aem";
import { nodeListArray } from "../../../../global/js/utils/dom";

const selectors = {
    tab: "[data-tabs]",
    panel: "[data-tab-panel]",
    trigger: "[data-tab-trigger]",
    tablist: "[role=tablist]"
};

const tabComponents = nodeListArray(document.querySelectorAll(selectors.tab));

function addAriaEnhancements(component = {}) {
    const { triggers, panels } = component;
    panels.forEach((panel, index) => {
        panel.setAttribute("aria-labelledby", triggers[index].getAttribute("id"));
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
    });
}

function createTabList(component = {}) {
    const { panels, tablist, triggers } = component;
    const items = triggers.map((trigger, index) => {
        const id = trigger.getAttribute("id");
        const text = trigger.textContent.trim();
        const panelId = panels[index].getAttribute("id");

        // Hide original trigger nodes from page & remove their ID attribute
        // (which get repurposed to panel `aria-labelledby`); avoids duplication
        // of IDs in the page;
        // - Headings stay in-place for print styles and any browser reader view
        //   page rendering
        trigger.removeAttribute("id");
        trigger.classList.add("visually-hidden");

        return `<li role="presentation"><a class="tab" id="${id}" href="#${panelId}" role="tab" tabindex="-1" data-index="${index}"><span>${text}</span></a></li>`;
    });

    tablist.innerHTML = items.join("");
    tablist.hidden = false;
}

function tabListClickEvent(component = {}) {
    const { tablist } = component;
    tablist.addEventListener(
        "click",
        (e) => {
            const tab = e.target.closest("a");
            e.preventDefault();
            if (tab) {
                swapPanel(component, tab);
            }
        },
        false
    );
}

function tabListKeyDownEvent(component = {}) {
    const { panels, tabs, tablist } = component;
    tablist.addEventListener(
        "keydown",
        (e) => {
            const index = tabs.indexOf(e.target);
            let direction;

            // For determining which arrow keys were used, `e.code` is the first
            // property to check. However, IE 11 doesn't understand it, returning `undefined`.
            // `e.which` and `e.keyCode` are deprecated properties (modern browsers could remove them at any time), but IE11 understands both - and won't change.

            switch (e.code || e.keyCode) {
                case "ArrowLeft":
                case 37:
                    direction = index - 1;
                    break;
                case "ArrowRight":
                case 39:
                    direction = index + 1;
                    break;
                case "ArrowDown":
                case 40:
                    e.preventDefault();
                    panels[index].focus();
                    return;
            }

            if (tabs[direction]) {
                e.preventDefault();
                swapPanel(component, tabs[direction]);
            }
        },
        false
    );
}

function showPanel(component, tab) {
    const { panels } = component;
    const index = Number(tab.getAttribute("data-index"));
    tab.removeAttribute("tabindex");
    tab.setAttribute("aria-selected", true);
    panels[index].hidden = false;

    // Broadcast change details via custom event
    window.dispatchEvent(
        new CustomEvent(EVENTS.TABS.TAB_OPENED, {
            detail: {
                tab: tab,
                target: panels[index]
            }
        })
    );
}

function hidePanel(component, tab) {
    const { panels } = component;
    const index = Number(tab.getAttribute("data-index"));
    tab.setAttribute("tabindex", "-1");
    tab.removeAttribute("aria-selected");
    panels[index].hidden = true;
}

function swapPanel(component, tab) {
    if (component.active !== tab) {
        tab.focus();
        showPanel(component, tab);
        hidePanel(component, component.active);
        component.active = tab;
    }
}

function initTabComponent(component) {
    const tabComponent = {
        component,
        panels: nodeListArray(component.querySelectorAll(selectors.panel)),
        triggers: nodeListArray(component.querySelectorAll(selectors.trigger)),
        tablist: component.querySelector(selectors.tablist),
        tabs: null
    };

    addAriaEnhancements(tabComponent);
    createTabList(tabComponent);

    // Update tabComponent stored info...
    tabComponent.tabs = nodeListArray(tabComponent.tablist.querySelectorAll("a")).map((tab, index) => {
        if (index === 0) {
            tabComponent.active = tab;
            showPanel(tabComponent, tab);
        } else {
            hidePanel(tabComponent, tab);
        }

        return tab;
    });

    // ...before adding tablist events
    tabListClickEvent(tabComponent);
    tabListKeyDownEvent(tabComponent);

    return tabComponent;
}

export default (function createTabs() {
    // Early exit rules
    if (isAuthor() || !tabComponents.length) {
        return;
    }

    tabComponents.forEach((component) => initTabComponent(component));
})();
