/**
 * Card (Animate) Component
 * ========================
 *
 * 1). If visitor's browser/OS is NOT set to `prefers-reduced-motion`
 * 2.) When:
 *     -- Viewport is at tablet width, or wider
 *     -- Card is set to animate & is in view...
 *
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { nodeListArray } from "../../../../global/js/utils/dom";
import { prefersReducedMotion } from "../../../../global/js/utils/window";

const CARDS = nodeListArray(document.querySelectorAll(`.card-inset[data-animatein]`));

function tearDownCardAnimations() {
    CARDS.forEach((card) => {
        card.classList.remove(card.getAttribute("data-animatein"));
        card.style.opacity = 1;
    });
}

function setUpCardAnimations() {
    CARDS.forEach((card) => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].intersectionRatio <= 0) {
                    return;
                }

                card.classList.add(card.getAttribute("data-animatein"));
                observer.unobserve(card);
            },
            { threshold: 1 }
        );

        card.style.opacity = 0;
        observer.observe(card);
    });
}

function handleViewportSwitch(e) {
    const { matches } = e.detail;

    if (!matches) {
        tearDownCardAnimations();
        return;
    }

    setUpCardAnimations();
}

export default (function cardIconText() {
    if (prefersReducedMotion() || !window.FSSA || window.FSSA.isAuthor) {
        return;
    }

    window.addEventListener(EVENTS.VIEWPORT_WATCHER.TABLET_SWITCH, handleViewportSwitch, false);
})();
