/**
 * @note on A11y:
 * Navigation buttons don't require `aria-haspopup`
 * - Should this be added in future?
 *      Only if we introduce a jump in element focus.
 *      Not necessary at the moment; sub-navigation sits immediately after
 *      triggering button, natural tab order works as expected for screen readers
 * - Only exception is the search button for search panel (opens/close functionality) on desktop
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// APIs
import Accordion from "../../../../global/js/api/accordion";

// Utils
import A11y from "../../../../global/js/utils/a11y";
import Panel from "../../../../global/js/utils/panel";
import { isAuthor } from "../../../../global/js/utils/aem";
import { nodeListArray } from "../../../../global/js/utils/dom";
import { createElement, isElement, swapTag } from "../../../../global/js/utils/element";

// COMPONENT CONFIG
const selectors = {
    container: ".navigation-container",
    menu: "aria-controls='navigation'",
    nav: "#navigation",
    navItem: "data-nav",
    navExtra: "#navigation-extra",
    navExtraItem: "li.navxtra-item",
    searchMenu: "data-search",
    search: "#navigation-quicksearch",
    subnav: "#subnavigation",
    subnavPanel: "data-subnav='panel'",
    languageToggle: ".js-language-toggle"
};

const constants = {
    HK_EN_RETAIL: "hk/en/retail",
    HK_ZH_RETAIL: "hk/zh/retail",
    PAGE_NOT_FOUND: "PAGE NOT FOUND"
};

const store = {
    accordion: null,
    navItems: [],
    navExtraItems: [],
    subPanels: [],
    open: false,
    type: "mobile"
};

// ESSENTIAL ELEMENTS
const navigation = document.querySelector(selectors.nav);
const subnavigation = document.querySelector(selectors.subnav);
const menuBtn = document.querySelector(`[${selectors.menu}]`);
const navigationExtra = document.querySelector(selectors.navExtra);

// OPTIONAL ELEMENTS
// Search (or dummy) elements
// Dummy element alternatives used if search elements are missing in page,
// i.e. search page has not been set in home template navigation settings
// - Allows element-based changes to run without error
const search =
    document.querySelector(selectors.search) ||
    createElement("div", {
        id: selectors.search.replace(/^#/, "")
    });

let searchBtn =
    document.querySelector(`[${selectors.searchMenu}]`) ||
    createElement("a", {
        href: "#dummySearchBtn",
        [selectors.searchMenu]: ""
    });

/**
 * Helper function:
 * Collect an arrays of elements
 * -- If root not specified, defaults to `document`
 * @return  {Array}
 */
function getArrayOfElements(selector, root = document) {
    return nodeListArray(root.querySelectorAll(selector));
}

/**
 * Helper function:
 * Get an up-to-date collection of navigation links/button elements
 * -- These items change in the switch from `a`-tags to `button`s and
 *    between Accordion setup/destruction
 * @return  {Array}
 */
function getNavLinks() {
    return getArrayOfElements(`[${selectors.navItem}]`, navigation);
}

/**
 * Helper function:
 * Get an up-to-date collection of sub-navigation panels
 * @return  {Array}
 */
function getSubPanels() {
    return getArrayOfElements(`[${selectors.subnavPanel}]`, subnavigation);
}

/**
 * Helper function
 * Moving the navigation extra list items into a list element
 * @return  {Element}
 */
function moveExtraListItems(targetList) {
    targetList.append(...store.navExtraItems);
    return targetList;
}

/**
 * Ensure NUMBER of main navigation links/buttons & sub-navigation panels MATCH
 * -- Each main navigation item must have a related panel
 * -- Sub-navigation panels are controlled by content editing, so it is possible
 *   that editors might accidentally create a mis-match
 * @return  {Boolean}
 */
function checkPrerequisites(links, panels) {
    if (links.length !== panels.length) {
        console.error(
            `Navigation error: Expected number of navigation top-level links (${links.length}) & sub-navigation panels available (${panels.length}) to match.`
        );
        return false;
    }

    return true;
}

/**
 * Create a unique sub-navigation string identifier
 * -- Will become sub-navigation panel id attribute
 * -- Suitable for tying trigger link/button & panel together
 * -- NOT using navItem link text; this might cause issues with localization
 *    especially with non-latin alphabets. Using page node name is safer
 * @return  {String}
 */
function createId(link) {
    return `subnav-${link.pathname.match(/([^/.]*)\.html$/i)[1]}`;
}

/**
 * Turn an a-tag into a button
 * -- Links must do one thing only and are reserved for moving to pages
 * -- Buttons are the better choice for hiding/showing other page elements;
 *    as per JavaScript enhancement
 * @return  {Element}
 */
function transformLinkToButton(link) {
    const button = swapTag(link, "button");
    button.setAttribute("data-href", button.getAttribute("href"));
    button.removeAttribute("href");
    return button;
}

function updateStatus(status = "mobile") {
    const switchDirection = (() => {
        if (status === "desktop") {
            return ["mobile", "desktop"];
        }
        return ["desktop", "mobile"];
    })();

    store.type = status;

    return [navigation, navigationExtra, search].forEach((element) => element.classList.replace(...switchDirection));
}

/**
 * Ensure all panels are closed
 * -- Optional exception element can be filtered-out
 * @return  {Array}
 */
function closeAll(exception, options = {}) {
    const extras = (() => {
        return store.type === "mobile" ? [menuBtn] : [];
    })();

    // Update latest navItems...
    store.navItems = getNavLinks();

    return store.navItems
        .concat(searchBtn, extras)
        .filter((item) => item !== exception)
        .forEach((item) => Panel.close(item, options));
}

/**
 * Build the MOBILE navigation view
 * -- Tear-down any desktop-specific view markup & events
 * -- Calls on the Accordion API
 * -- Updates status
 */
function mobileView() {
    // Tear-down any desktop navigation:
    navigation.removeEventListener("click", desktopButtonClickEvent, false);
    navigation.removeEventListener("focus", desktopButtonFocusEvent, true);

    // Update status:
    updateStatus("mobile");

    // Set starting-positions:
    moveExtraListItems(navigation.querySelector("ul.nav-group"));
    menuBtn.hidden = false;
    navigation.hidden = true;
    navigationExtra.hidden = true;
    search.hidden = true;
    Panel.close(menuBtn, { immediate: true });
    Panel.close(searchBtn, { immediate: true });

    if (!store.accordion) {
        store.accordion = Accordion(navigation, {
            ariaUntouched: true,
            icon: "plus",
            skipTransitionEnd: true,
            // We set this once in the navigation init phase; accordion can use it
            // rather than adding another
            targetSelector: `[${selectors.subnavPanel}]`,
            triggerSelector: `[${selectors.navItem}]`,
            type: "single"
        });
    } else {
        store.accordion.setup();
    }

    store.accordion.init();
}

/**
 * DESKTOP view click events: open/close sub-navigation panels
 */
function desktopButtonClickEvent(e) {
    const { target } = e;
    const button = target.closest(`button[${selectors.navItem}]`);

    if (!isElement(button)) {
        return;
    }

    // Open selected...
    Panel.toggle(button);
    closeAll(button, { immediate: true });
}

/**
 * DESKTOP view focus events:
 * -- For visitors using keyboard/switch devices to navigate our website
 */
function desktopButtonFocusEvent(e) {
    const { target } = e;
    const button = target.closest(`button[${selectors.navItem}]`);

    if (!isElement(button)) {
        return;
    }

    closeAll();
}

/**
 * Build the DESKTOP navigation view
 * -- Tear-down any mobile-specific view markup
 * -- Order of steps is important, e.g. destroying any Accordion must come first
 * -- Adds click & focus event listeners
 * -- Updates status
 */
function desktopView() {
    // Tear-down any mobile navigation:
    if (store.accordion) {
        store.accordion.destroy();
    }

    // Update status:
    updateStatus("desktop");
    navigation.addEventListener("click", desktopButtonClickEvent, false);
    navigation.addEventListener("focus", desktopButtonFocusEvent, true);

    // Set starting-positions:
    moveExtraListItems(navigationExtra.querySelector("ul"));
    menuBtn.hidden = true;
    search.hidden = true;
    navigationExtra.hidden = false;
    Panel.open(menuBtn, { immediate: true });
    closeAll(null, { immediate: true });
}

/**
 * Listen-out for the mobile/desktop viewport-switch custom event
 * -- Calls for the appropriate navigation view to run
 * -- Automatically works on window resize, orientation switch, etc.
 */
function handleViewportSwitch(e) {
    const { matches } = e.detail;

    if (matches) {
        desktopView();
    } else {
        mobileView();
    }
}

/**
 * Set-up the MOBILE view (hamburger) menu button
 */
function menuButton() {
    A11y.setControls(menuBtn, navigation);
    menuBtn.addEventListener(
        "click",
        () => {
            Panel.toggle(menuBtn);
            Panel.toggle(searchBtn);
        },
        false
    );
    navigation.classList.add("closed");
    Panel.transitionEnd(navigation);
}

/**
 * Update the language button for HK-Retail website
 */
function updateLanguageButton() {
	const pageTitle = String(document.title).toUpperCase();
	const languageToggleBtn = document.querySelector(selectors.languageToggle);
	if (String(document.title).toUpperCase() == constants.PAGE_NOT_FOUND || !isElement(languageToggleBtn)) {
		return;
	}
	checkAndUpdateLanguageBtnLink(languageToggleBtn);
}

/**
 * Check and Update the language button link for HK-Retail website
 */
function checkAndUpdateLanguageBtnLink(languageToggleBtn) {
	const currentPath = location.pathname;
	let languageToggleBtnLink;
	if (currentPath.includes(constants.HK_EN_RETAIL)) {
		languageToggleBtnLink = currentPath.replace(constants.HK_EN_RETAIL, constants.HK_ZH_RETAIL);
	} else if (currentPath.includes(constants.HK_ZH_RETAIL)) {
		languageToggleBtnLink = currentPath.replace(constants.HK_ZH_RETAIL, constants.HK_EN_RETAIL);
	}
	if (languageToggleBtnLink) {
		getPageStatus(languageToggleBtnLink)
			.then((pageStatus) => {
				if (pageStatus.pathExists) {
					languageToggleBtn.setAttribute("href", languageToggleBtnLink);
					languageToggleBtn.parentElement.hidden = false;
				}
			})
			.catch((error) => {
				console.warn(`Fetch operation error: ${error.message}`);
			});
	}
}

/**
 * Get page status
 */
async function getPageStatus(pagePath) {
	const pageStatusEndpoint = "/bin/fsi/pageStatus?path={aemPagePath}&website=fssa";
	const response = await fetch(pageStatusEndpoint.replace("{aemPagePath}", pagePath));
	if (!response.ok) {
		throw new Error(`HTTP error status: ${response.status}`);
	}
	return await response.json();
}

/**
 * Set-up the DESKTOP view (magnifying glass) search button
 */
function searchButton() {
    const header = searchBtn.closest(".header-layout");

    A11y.setControls(searchBtn, search);
    Panel.transitionEnd(search);
    searchBtn = transformLinkToButton(searchBtn);
    searchBtn.setAttribute("aria-haspopup", true);
    // ^^ ARIA haspopup added to search icon; clicking it will jump
    //    cursor focus to the search form - `aria-haspopup` notifies
    //    screen readers of this...

    searchBtn.addEventListener(
        "click",
        (e) => {
            // Reset the top CSS rule to cover edge-case where header depth changes,
            // e.g. window resizing, orientation change
            // This CSS only affects desktop navigation (absolutely positioned)
            search.style.top = `${header.clientHeight}px`;
            Panel.toggle(searchBtn);
            closeAll(searchBtn);

            if (searchBtn.getAttribute("aria-expanded") === "true") {
                // Jump focus to the input field
                search.querySelector("input:not([disabled])").focus();
            }
        },
        false
    );
}

/**
 * A single point of truth for determining if ANY navigation element
 * is in an open state. Uses a custom event to keep track
 */
function detectNavigationOpen() {
    const navigationContainer = navigation.parentElement;
    const navigationStatusEvent = new CustomEvent(EVENTS.NAVIGATION.NAVIGATION_STATUS, {
        detail: {
            open: () => {
                return !!navigationContainer.querySelector(`[aria-expanded="true"]:not([hidden]:not([disabled]))`);
            }
        }
    });

    // Fire the custom event to get up-to-date status
    navigationContainer.addEventListener(
        "click",
        (e) => {
            window.dispatchEvent(navigationStatusEvent);
        },
        false
    );

    // Have the window listen-in on custom event;
    // only updates open status if there is a change
    window.addEventListener(
        EVENTS.NAVIGATION.NAVIGATION_STATUS,
        (e) => {
            const { open = () => {} } = e.detail;
            const currentStatus = open();

            if (store.open !== currentStatus) {
                store.open = currentStatus;
            }

            return store.open;
        },
        false
    );
}

/**
 * Kick-off everything navigation...
 */
function init() {
    const links = getNavLinks();
    const panels = getSubPanels();

    if (!checkPrerequisites(links, panels)) {
        return;
    }

    // Run through the main navigation items
    // 1.) Set-up aria connection between link & respective sub-navigation panel
    // 2.) Move sub-navigation panel to be directly after it's link
    // 3.) Transform main navigation links into buttons
    // 4.) Store items for later use
    links.forEach((link, index) => {
        const subPanel = panels[index];
        subPanel.setAttribute("id", createId(link));
        link.after(subPanel);
        A11y.setControls(link, subPanel);
        Panel.close(subPanel, { immediate: true });
        Panel.transitionEnd(subPanel);
        store.navItems.push(transformLinkToButton(link));
        store.subPanels.push(subPanel);
    });

    // Store the navigation extra list items,
    // in preparation for moving them into the correct list
    // -- Mobile: inside navigation list
    // -- Desktop: inside navigation-extra list (their original location)
    store.navExtraItems = getArrayOfElements(selectors.navExtraItem, navigationExtra);

    menuButton();
    searchButton();
    updateLanguageButton();
    detectNavigationOpen();
    window.addEventListener(EVENTS.VIEWPORT_WATCHER.DESKTOP_SWITCH, handleViewportSwitch, false);
}

export default (function initNavigation() {
    // Quick escape clauses:
    if (
        // CMS in author mode; allow editors to edit (sub-navigation, especially)
        isAuthor() ||
        // Templates that don't include navigation/sub-navigation components
        !isElement(navigation) ||
        !isElement(subnavigation)
    ) {
        return;
    }

    subnavigation.remove();
    init();

    // Good house-keeping (and accessibility)
    // Listen for any clicks/focus OUTSIDE navigation
    // -- Then only if navigation is open, close it
    ["click", "focus"].forEach((eventType) => {
        window.addEventListener(
            eventType,
            (e) => {
                const { target } = e;

                if (isElement(target) && store.open) {
                    const isTargetWithinNavigation = !!target.closest(`${selectors.container}`);
                    return !isTargetWithinNavigation && closeAll();
                }
            },
            true
        );
    });
})();
