/**
 * Card (Profile) Component
 * ========================
 * Profiles can have video elements that launch in an modal overlay.
 * - Calls on the Modal API
 * - Video iframe HTML comes courtesy of the Video component
 *
 */

// APIs
import Modal from "../../../../global/js/api/modal";

// Utils
import { nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";

export default (function cardProfileProfile() {
    const profiles =
        // Capture all the profile cards
        nodeListArray(document.querySelectorAll("article.card--profile"))
            // Filter on the profiles that have videos
            .filter((profile) => profile.querySelector("[data-video]"));

    profiles.forEach((profile) => {
        const button = profile.querySelector("button");
        const video = profile.querySelector(`script[type="text/template"]`);

        if (!isElement(button)) {
            return;
        }

        const videoModal = Modal({
            id: "VIDEO_OVERLAY",
            trigger: button,
            type: "element",
            content: {
                body: video.innerHTML
            },
            additionalCss: ["modal--video"],
            closeIcon: "close",
            closeTextHidden: true
        });

        button.addEventListener(
            "click",
            () => {
                videoModal.open();
            },
            false
        );
    });
})();
