// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";

import { isDate, removeDates, setDateString } from "./datefields";

const MULTI_SELECTORS = nodeListArray(document.querySelectorAll(".multi-selector"));
const RESET_BTNS = nodeListArray(document.querySelectorAll(".multi-selector--cta .btn-reset"));
const SUBMIT_BTNS = nodeListArray(document.querySelectorAll(".multi-selector--cta .btn-submit"));

function serializeFormData(form) {
    const FORM_DATA = new FormData(form);
    const data = {};

    for (const key of FORM_DATA.keys()) {
        const value = FORM_DATA.get(key);
        // Don't capture empty string values
        if (value) {
            data[key] = value;
        }
    }

    return data;
}

function fireCustomEvent(e, options = {}) {
    const { target } = e;
    const { reset } = options;
    const FORM = target.closest("form");

    if (!isElement(FORM)) {
        return;
    }

    const FORM_DATA = (() => {
        if (reset) {
            const FORM_ERRORS = nodeListArray(FORM.querySelectorAll("div.error[aria-live]"));
            FORM_ERRORS.forEach((error) => (error.textContent = ""));
            return removeDates(serializeFormData(FORM));
        }
        return serializeFormData(FORM);
    })();

    const DATE_FROM_STRING = setDateString("dateFrom", FORM_DATA);
    const DATE_TO_STRING = setDateString("dateTo", FORM_DATA);

    window.dispatchEvent(
        new CustomEvent(EVENTS.MULTISELECTOR.OPTION_CHANGED, {
            detail: {
                target,
                data: {
                    ...FORM_DATA,
                    dateFrom: isDate(DATE_FROM_STRING),
                    dateTo: isDate(DATE_TO_STRING),
                    reset
                }
            }
        })
    );
}

export default (function multiSelectPartial() {
    if (!MULTI_SELECTORS.length) {
        return;
    }

    MULTI_SELECTORS.forEach((selector) => {
        selector.addEventListener("change", (e) => fireCustomEvent(e), false);
    });

    RESET_BTNS.forEach((reset) => {
        reset.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                fireCustomEvent(e, { reset: true });
            },
            false
        );
    });

    // Guarantee data is at least populated on submit;
    // covers edge-case of zero data because no filters have been changed
    SUBMIT_BTNS.forEach((submit) => {
        submit.addEventListener("click", (e) => fireCustomEvent(e), false);
    });
})();
