/**
 * serialMaker
 * ===========
 * An API for generating a serial number sequence
 *
 * @param {Object} config
 * @return {Object}
 */
export default function serialMaker(config = {}) {
    // Defaults
    const { prefix = "" } = config; // Optional prefix, e.g. for name-spacing
    let { sequence = 0 } = config; // Optional sequence starting value

    /**
     * Update the sequence setting
     *
     * @param {Number} newSequence
     */
    function setSequence(newSequence) {
        sequence = Number(newSequence);
    }

    /**
     * Output the next serial in the sequence
     *
     * @return {String}
     */
    function generate() {
        const result = `${prefix}${sequence}`;
        sequence += 1;
        return result;
    }

    return {
        setSequence,
        generate
    };
}
