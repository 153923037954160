/**
 * CookiesTerms API
 * ================
 * Extension of Cookies API that handles site disclaimer/terms cookie getting/setting, etc.
 *
 * Index (API method / internal function name)
 *
 * - CookiesTerms.name (returned string value) / termsCookieName()
 * - CookiesTerms.get() / getTermsCookie()
 * - CookiesTerms.set() / setTermsCookie()
 * - CookiesTerms.expire() / expireCookie()
 * - CookiesTerms.redirect() / getRedirectLocation()
 *
 * ... See the specific functions below for more information.
 *
 * @return {Object}
 */

// APIs:
import Cookies from "./cookies";

const CURRENT_LOCALE = window.FSSA.currentLocale || {};

/**
 * Generate and return the site's cookie name based on its locale
 * -- Cookie name pattern: `FSSA-{COUNTRY_CODE}-{LANGUAGE_CODE}-{INVESTOR_TYPE}`
 *    e.g. "FSSA-AU-EN-ADVISER"
 * @param   {Object}  locale -- Defaults to CURRENT_LOCALE, if not provided
 * @return  {String}
 */
function termsCookieName(locale = CURRENT_LOCALE) {
    const { country, language, investorType } = locale;
    // Filter to remove falsy values
    return ["FSSA", country, language, investorType]
        .filter((item) => item)
        .join("-")
        .toUpperCase();
}

/**
 * Return an array of all the FSSA  terms cookies
 * -- Function internals include an array of FSSA non-terms cookie names to
 *    filter-out of this collection
 * @return  {Array}
 */
function getAllTermsCookies() {
    return (
        Object.keys(Cookies.data())
            // Match ALL FSSA-namespaced cookies...
            .filter((cookieName) => {
                return /^FSSA/i.test(cookieName);
            })
            // Future-proofing: add any non-terms FSSA cookie names
            // to the `FILTER_OUT` array below...
            .filter((cookieName) => {
                const FILTER_OUT = ["FSSA-ALLOW-COOKIES"];
                return !FILTER_OUT.includes(cookieName);
            })
    );
}

/**
 * Return the value of an FSSA terms cookie, if it exists
 * @param   {Object}  locale -- Defaults to CURRENT_LOCALE, if not provided
 * @return  {String}
 */
function getTermsCookie(locale = CURRENT_LOCALE) {
    const NAME = termsCookieName(locale);
    return Cookies.get(NAME);
}

/**
 * Create/update an FSSA terms cookie
 * @param   {Object}  locale -- Defaults to CURRENT_LOCALE, if not provided
 * @param   {Object}  config -- Additional cookie configuration, e.g. alternative expiry, etc.
 * @return  {Undefined}
 */
function setTermsCookie(locale = CURRENT_LOCALE, config = {}) {
    const NAME = termsCookieName(locale);
    const THIRTY_DAYS_IN_SECONDS = 2592000; // Default, if no config.expiry available

    const COOKIE_SETTINGS = {
        expiry: config.expiry || THIRTY_DAYS_IN_SECONDS,
        pathname:
            config.pathname ||
            `/content/fssainvestors/${locale.country}/${locale.language}/${locale.investorType}.html`,
        ...locale
    };

    const EXPIRY_DATE = (() => {
        return new Date(Date.now() + COOKIE_SETTINGS.expiry * 1000).toUTCString();
    })();

    const COOKIE_VALUE = [
        `${NAME.replace(/^FSSA-/, "")}=Y`,
        `pagePath=${COOKIE_SETTINGS.pathname}`,
        // ^^ @note: Stores where the global site should redirect to, if previously set
        //           FSSA Terms cookie is found.
        `expires=${EXPIRY_DATE}`
    ].join("|");

    // Expire any other FSSA site terms cookies; ensures only one possible redirect from global site
    expireAllTermsCookies();

    return Cookies.set(
        NAME,
        COOKIE_VALUE,
        {
            "max-age": COOKIE_SETTINGS.expiry,
            "SameSite": "lax"
        },
        // Older versions of Chrome block cookies over `http`,
        // So, don't pass secure flag on `localhost` environments - be nice to developers
        location.hostname.toUpperCase() !== "LOCALHOST" ? { secure: true } : {}
    );
}

/**
 * Delete an FSSA terms cookie
 * @param   {Object}  config -- Setting the `expireAll` flag to `true` will remove all
 *                              stored terms cookies
 * @param   {Object}  locale -- Defaults to CURRENT_LOCALE, if not provided
 * @return  {Undefined}
 */
function expireCookie(config = {}, locale = CURRENT_LOCALE) {
    const NAME = termsCookieName(locale);
    const { expireAll } = config;

    if (expireAll) {
        expireAllTermsCookies();
        return;
    }

    Cookies.expire(NAME);
}

/**
 * Delete all stored FSSA terms cookies
 * @return  {Undefined}
 */
function expireAllTermsCookies() {
    const ALL_TERMS = getAllTermsCookies();
    console.info("Expiring the following FSSA cookies: ", ALL_TERMS);
    return ALL_TERMS.map((cookieName) => Cookies.expire(cookieName));
}

/**
 * Get any redirect location for the Global site to move to.
 * Any redirect value is based on the presence of a previously accepted FSSA site terms cookie
 * @return  {String|Undefined}
 */
function getRedirectLocation() {
    const ALL_TERMS = getAllTermsCookies();

    if (!ALL_TERMS || !ALL_TERMS.length) {
        return;
    }

    const COOKIE_VALUES = Cookies.get(ALL_TERMS[0]);
    const PAGE_PATH = COOKIE_VALUES.match(/pagePath=([^|]*)/i);

    return PAGE_PATH && PAGE_PATH.length > 1 && PAGE_PATH[1]
        ? `${location.origin}${PAGE_PATH[1]}${location.search}`
        : "";
}

/**
 * Public API
 * @return  {Object}
 */
export default (function CookiesTerms() {
    return {
        name: termsCookieName(),
        get: getTermsCookie,
        set: setTermsCookie,
        expire: expireCookie,
        redirect: getRedirectLocation
    };
})();
