/**
 * Price & Performance Form Component
 * ==================================
 * Most component script can be found in file:
 *
 * -- `form/common/scripts/multiselect.js`
 * -- `form/common/scripts/fundfield.js`
 *
 * These files are shared by components:
 *
 * 1). Price & Performance
 * 2). Documents & Factsheets
 * 3). Dividends (Fund distribution)
 *
 */

// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { htmlStringToNodeArray, nodeListArray } from "../../../../global/js/utils/dom";
import { isElement } from "../../../../global/js/utils/element";
import Panel from "../../../../global/js/utils/panel";

import { getISODateString } from "../../common/scripts/datefields";

const FORM = document.querySelector("form.priceperformance-form");

const SELECTORS = {
    DATA_ACCORDION: "data-accordion",
    MESSAGE: ".message-noresults",
    ROWCOUNT: "data-rowcount",
    EXPAND_ROWS_BTN: ".js-price-performance-expand-all-rows",
    TABLE_PARENT_DIV: ".table.table--data.table--scrollable",
    TABLE_PARENT_DIV_HIDDEN: ".table.table--data.table--scrollable[hidden]",
    GO_BTN: ".js-price-performance-btn .btn-submit",
    HISTORICAL_PRICE: ".fund-price-historical-data",
    PRICE_PANEL: "price--panel0",
    ACTIVE: "active"
};

const ELEMENTS = {};
let multiSelectorData = {};

const expandRowsButton = document.querySelector(SELECTORS.EXPAND_ROWS_BTN);
const goButton = document.querySelector(SELECTORS.GO_BTN);
const historicalPriceWrapperElement = document.querySelector(SELECTORS.HISTORICAL_PRICE);
const pricePanel = document.getElementById(SELECTORS.PRICE_PANEL);

if (isElement(pricePanel)) {
    ELEMENTS.ACCORDION_MULTI = pricePanel.querySelector(`div[${SELECTORS.DATA_ACCORDION}]`);
}

function expandCollapseAccordions() {
    const { collapseAllRowsText, expandAllRowsText } = expandRowsButton.dataset;
    nodeListArray(FORM.querySelectorAll(`[data-accordion-trigger]`)).forEach((trigger) => {
        if (trigger.getAttribute("aria-expanded") === "true") {
            expandRowsButton.textContent = collapseAllRowsText;
            Panel.close(trigger, {
                immediate: true
            });
        } else {
            expandRowsButton.textContent = expandAllRowsText;
            Panel.open(trigger, {
                immediate: true
            });
        }
    });
}

function clearResults(showMultiAccordion) {
    ELEMENTS.ACCORDION_MULTI.hidden = showMultiAccordion;
    historicalPriceWrapperElement.innerHTML = "";
}

function hideNoResultTables() {
    ELEMENTS.TABLES.forEach((table) => {
        const ROWS_MAX = Number(table.getAttribute(SELECTORS.ROWCOUNT) || 0);
        const ROWS_HIDDEN = table.querySelectorAll("tbody > tr[hidden]").length;
        const RESULT = ROWS_MAX > ROWS_HIDDEN;

        const tableParentDiv = table.closest(SELECTORS.TABLE_PARENT_DIV);
        if (isElement(tableParentDiv)) {
            tableParentDiv.hidden = !RESULT;
        }
    });
}

function hideNoResultAccordions() {
    const { strategy, fund } = multiSelectorData;

    nodeListArray(FORM.querySelectorAll(`[data-accordion-target]`)).forEach((target) => {
        const TOTAL_TABLES = target.querySelectorAll(SELECTORS.TABLE_PARENT_DIV).length;
        const TABLES_HIDDEN = target.querySelectorAll(SELECTORS.TABLE_PARENT_DIV_HIDDEN).length;
        const RESULT = TOTAL_TABLES != TABLES_HIDDEN;

        target.parentElement.hidden = !RESULT;
        if (strategy === undefined && fund === undefined) {
            Panel.close(target, {
                immediate: true
            });
        } else if (RESULT) {
            Panel.open(target, {
                immediate: true
            });
        }
    });
}

function appendDatesToQueryString(url, dateFrom, dateTo) {
    const [path, queryString] = url.split("?");
    const params = new URLSearchParams(queryString);

    params.set("dateFrom", getISODateString(dateFrom));
    params.set("dateTo", getISODateString(dateTo));
    return `${path}?${params.toString()}`;
}

function fetchAndShowHistoricalPriceData() {
    const { fund, dateFrom, dateTo } = multiSelectorData;

    if (!isElement(historicalPriceWrapperElement)) {
        return;
    }

    clearResults(true);

    const fundTrElement = document.querySelector(`tr[data-fund=${fund}]`);
    if (isElement(fundTrElement)) {
        let noResultsMessage;
        const noResultsMessageElement = document.querySelector(SELECTORS.MESSAGE);

        if (isElement(noResultsMessageElement)) {
            noResultsMessage = noResultsMessageElement.textContent;
        }

        getHistoricalPriceData(appendDatesToQueryString(fundTrElement.dataset.historicalPriceUrl, dateFrom, dateTo))
            .then((text) => {
                if (!text) {
                    historicalPriceWrapperElement.innerHTML = `<p>${noResultsMessage}</p>`;
                    return;
                }

                historicalPriceWrapperElement.innerHTML = text;
            })
            .catch((error) => {
                console.warn(`Fetch operation error: ${error.message}`);
            });
    }
}

async function getHistoricalPriceData(priceUrl) {
    const response = await fetch(priceUrl);
    if (!response.ok) {
        throw new Error(`HTTP error status: ${response.status}`);
    }
    return await response.text();
}

function validateForm(data = {}) {
    const FORM_ERRORS = FORM.querySelector("div.error");
    const { dateFrom, dateTo, fund } = data;
    const dateValid = dateTo > dateFrom;

    function addError(validityTest, key) {
        if (!validityTest) {
            FORM_ERRORS.append(...htmlStringToNodeArray(`<p>${FORM.dataset[key]}</p>`));
        }
    }

    FORM_ERRORS.textContent = "";

    if (fund && dateValid) {
        return true;
    }

    addError(fund, "emptyFundMessage");
    addError(dateValid, "dateMessage");
    return false;
}

export default (function pricePerformanceForm() {
    if (!isElement(FORM)) {
        return;
    }

    if (isElement(expandRowsButton)) {
        expandRowsButton.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                expandCollapseAccordions();
            },
            false
        );
    }

    if (isElement(goButton)) {
        goButton.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                if (validateForm(multiSelectorData)) {
                    fetchAndShowHistoricalPriceData();
                }
            },
            false
        );
    }

    ELEMENTS.TABLES = nodeListArray(FORM.querySelectorAll(`table[${SELECTORS.ROWCOUNT}]`));
    window.addEventListener(
        EVENTS.MULTISELECTOR.OPTION_CHANGED,
        (e) => {
            const { data } = e.detail;
            multiSelectorData = data;
            clearResults(false);
            hideNoResultTables();
            hideNoResultAccordions(data);
        },
        false
    );
})();
