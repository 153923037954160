// APIs
import CookiesTerms from "../../../../global/js/api/cookiesSiteTerms";

// Utilities
import { isAuthor } from "../../../../global/js/utils/aem";

export default (function globalRedirect() {
    const { currentLocale = {} } = window.FSSA;
    const { isVanityUrl } = window.FSSA.vanityDetails || {};
    const TERMS_COOKIE_REDIRECT = CookiesTerms.redirect();

    if (isAuthor() || !TERMS_COOKIE_REDIRECT || String(currentLocale.country).toUpperCase() !== "GLOBAL"
                   || isVanityUrl || String(document.title).toUpperCase() == "PAGE NOT FOUND") {
        return;
    }

    window.location = TERMS_COOKIE_REDIRECT;
})();
