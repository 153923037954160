// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import { nodeListArray } from "../../../../global/js/utils/dom";
import { detectOverflow, isElement } from "../../../../global/js/utils/element";
import { debounce } from "../../../../global/js/utils/event";

const tableParent = ".table-overflow";
const scrollNotification = ".scroll-notification";

function getTableComponents(container) {
    const element = container || document;
    return nodeListArray(element.querySelectorAll(tableParent));
}

function setTabIndex(tablesSubset) {
    const tables = tablesSubset || getTableComponents();

    tables.forEach((table) => {
        const notification = table.querySelector(scrollNotification);

        if (!isElement(notification)) {
            return;
        }

        if (!detectOverflow(table).horizontal) {
            table.removeAttribute("tabIndex");
            notification.hidden = true;
            return;
        }

        table.setAttribute("tabIndex", "0");
        notification.hidden = false;
    });
}

export default (function createScrollingTables() {
    setTabIndex();

    // @edgecase(s): Recalculate table overflow on:
    // 1). Browser window resize
    // 2). Accordion panel open
    // 3). Tab panel open
    window.addEventListener(
        "resize",
        debounce(() => setTabIndex()),
        false
    );
    window.addEventListener(EVENTS.ACCORDION.ACCORDION_OPENED, (e) => setTabIndex(), false);
    window.addEventListener(EVENTS.TABS.TAB_OPENED, (e) => setTabIndex(), false);
})();
