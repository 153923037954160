/**
 * Read More Component
 * ===================
 * Calls on the Panel utility to provide expand/collapse functionality & A11y enhancement.
 *
 */

import A11y from "../../../../global/js/utils/a11y";
import Panel from "../../../../global/js/utils/panel";
import { nodeListArray } from "../../../../global/js/utils/dom";

function switchButtonText(button) {
    const attrName = "data-text-switch";
    const text = button.querySelector(`[${attrName}]`);
    const switchCopy = text.getAttribute(attrName);
    text.setAttribute(attrName, text.textContent);
    text.textContent = switchCopy;
}

export default (function initReadMores() {
    const components = nodeListArray(document.querySelectorAll(".readmore[data-readmore]")).filter(
        (component) => component.getAttribute("data-readmore") !== "inactive"
    );

    if (window.FSSA && !window.FSSA.isAuthor) {
        components.forEach((component) => {
            const preview = component.querySelector(`[data-preview]`);
            const target = component.querySelector(`[data-expandable]`);
            const button = component.querySelector(`button`);
            const fadeout = component.getAttribute(`data-readmore`) === "fadeout";

            button.parentNode.hidden = false;
            target.setAttribute("tabindex", -1);

            A11y.setControls(button, target);
            Panel.close(button, { immediate: true });
            Panel.transitionEnd(target);

            button.addEventListener(
                "click",
                () => {
                    Panel.toggle(button);
                    switchButtonText(button);

                    if (fadeout) {
                        preview.classList.toggle("fadeout");
                    }

                    // On open, move focus to opened panel to ensure any contained links
                    // are next in tab order, plus button can be re-focussed at end of
                    // this content for closing
                    if (button.getAttribute("aria-expanded") === "true") {
                        target.focus();
                    }
                },
                false
            );
        });
    }
})();
