// APIs
import Modal from "../../../../global/js/api/modal";
import CookiesTerms from "../../../../global/js/api/cookiesSiteTerms";

// Utils
import { getPageTemplate, isAuthor } from "../../../../global/js/utils/aem";
import { isElement } from "../../../../global/js/utils/element";
import { nodeListArray } from "../../../../global/js/utils/dom";
import { checkAndRedirectToVanityUrl } from "../../../../global/js/utils/gateRedirect";

export default (function initNavigationRegionLink() {
    const regionLink = document.querySelector("#region");
    const promoTeasers = nodeListArray(document.querySelectorAll("[data-show-country-selector='true']"));
    const fssaBodyLayout = document.querySelector(".fssa-investors");
    const languageToggleBtn = document.querySelector(".js-language-toggle");
    let isPublish;
    let slamDoorJsonFilePath;

    if (isAuthor() || !isElement(regionLink)) {
        return;
    }

    if(isElement(fssaBodyLayout)){
        isPublish = fssaBodyLayout.dataset.publishView;
    }

    if(isPublish === "true"){
        slamDoorJsonFilePath = "/content/dam/fssainvestors/country-selector/slam-door-prompt-page-paths-publish.json";
    } else {
        slamDoorJsonFilePath = "/content/dam/fssainvestors/country-selector/slam-door-prompt-page-paths.json";
    }

    const { currentLocale = {}, currentPagePath } = window.FSSA;
    const { countrySelector, siteDisclaimer } = window.FSSA.elementStore || {};
    const { isVanityUrl, isVanityGeoTargeted } = window.FSSA.vanityDetails || {};
    const disclaimerCtas = siteDisclaimer.querySelector(`[data-disclaimer="footer"]`);

    const regionModal = buildModal(false, regionLink);

    window.FSSA.elementStore.regionModal = regionModal;
    languageToggleEvent();
    launchSlamDoor();

    function launchSlamDoor() {
        const siteTermsCookieName = CookiesTerms.get(currentLocale);
        if (!siteTermsCookieName) {
            getSlamDoorPromptPagePaths()
                .then((text) => {
                    let prompt = false;
                    for (let path of text) {
                        if(currentPagePath.includes(path)){
                            prompt = true;
                            break;
                        }
                    }
                    if (prompt) {
                        buildModal(true).open();
                    }
                })
                .catch((error) => {
                    console.warn(`Fetch operation error: ${error.message}`);
                });
        }
    }

    async function getSlamDoorPromptPagePaths() {
        const response = await fetch(slamDoorJsonFilePath);
        if (!response.ok) {
            throw new Error(`HTTP error status: ${response.status}`);
        }
        return await response.json();
    }

    function buildModal(isLocked, trigger) {
        return Modal({
            id: "REGION_OVERLAY",
            trigger: trigger,
            type: "element",
            content: {
                header: countrySelector,
                body: siteDisclaimer,
                footer: disclaimerCtas
            },
            locked: isLocked,
            additionalCss: ["modal--region"]
        });
    }

    function languageToggleEvent() {
        if (!isElement(languageToggleBtn)) {
            return;
        }
        languageToggleBtn.addEventListener(
            "click",
            (e) => {
                e.preventDefault();
                const languageToggleUrl = languageToggleBtn.getAttribute("href");
                if(CookiesTerms.get(currentLocale)){
                    updateTermsCookieOnLanguageToggle(languageToggleUrl);
                }
                window.location = languageToggleUrl;
            },
            false
        );
    }

    function updateTermsCookieOnLanguageToggle(languageToggleUrl) {
        const countryLanguageAudienceMap = {};
        countryLanguageAudienceMap.country = "hk";
        countryLanguageAudienceMap.investorType = "retail";
        if (languageToggleUrl.includes("/zh/")) {
            countryLanguageAudienceMap.language = "zh";
        } else {
            countryLanguageAudienceMap.language = "en";
        }
        if (isPublish === "true") {
            languageToggleUrl = languageToggleUrl.replace("/content/fssainvestors/", "").split("/").slice(0, 4).join("/") + ".html";
        } else {
            languageToggleUrl = languageToggleUrl.split("/").slice(0, 6).join("/") + ".html";
        }
        return CookiesTerms.set(countryLanguageAudienceMap, {
            pathname: languageToggleUrl
        });
    }

    regionLink.addEventListener(
        "click",
        (e) => {
            e.preventDefault();
            window.teaserTileRelPath = "";
            regionModal.open();
        },
        false
    );

    // On Global site, launch the region chooser
    if (String(currentLocale.country).toUpperCase() === "GLOBAL" && (getPageTemplate() === "HOME" || isVanityGeoTargeted)) {
        callIpLookUpTool()
            .then((response) => {
                const countryCode = response.countryCode.toLowerCase();
                let languageCode ="en";
                const currentLocale = window.FSSA.currentLocale;

                // if the country code returned in "GB" then replace it with "UK"
                if (countryCode == 'gb') {
                    countryCode = 'uk';
                }
                // Check for france, germany and japan and set languageCode appropriately.
                if (countryCode == 'fr') {
                    languageCode = 'fr';
                } else if (countryCode == 'de') {
                    languageCode = 'de';
                } else if (countryCode == 'jp') {
                    languageCode = 'ja';
                } else if (countryCode == 'hk' && currentLocale.language == 'zh') {
                    languageCode = 'zh';
                }

                window.FSSA.currentLocale = {country: countryCode, language: languageCode};
                if(getPageTemplate() === "HOME"){
                    regionModal.open();
                } else if (isVanityGeoTargeted && !location.pathname.includes(".html")) {
                    const TERMS_COOKIE_REDIRECT = CookiesTerms.redirect();
                    if(TERMS_COOKIE_REDIRECT && TERMS_COOKIE_REDIRECT.includes(`/${countryCode}/`)){
                        const cookieRedirectPath = new URL(TERMS_COOKIE_REDIRECT);
                        const defaultRedirectPath = cookieRedirectPath.pathname;
                        const urlParams = new URLSearchParams(location.search);
                        let audiencePath = defaultRedirectPath.replace("/content/fssainvestors", "");
                        audiencePath = audiencePath.substring(0, audiencePath.lastIndexOf('.'));
                        checkAndRedirectToVanityUrl(`${audiencePath}${location.pathname}`, defaultRedirectPath, urlParams);
                    } else {
                        buildModal(true).open();
                    }
                }
                countrySelector.querySelector("button[data-country-selector-toggle]").click();

            })
            .catch((error) => {
                console.warn(`Fetch operation error: ${error.message}`);
            });
    }

    async function callIpLookUpTool() {
        const response = await fetch("https://extreme-ip-lookup.com/json/?key=qgv9ro1OkiAdWETg3W19");
        if (!response.ok) {
            throw new Error(`HTTP error status: ${response.status}`);
        }
        return await response.json();
    }

    if (window.FSSA && !window.FSSA.isAuthor) {
        promoTeasers.forEach((promoTeaser) => {
            promoTeaser.addEventListener(
                "click",
                (e) => {
                    e.preventDefault();
                    window.teaserTileRelPath = promoTeaser.getAttribute("data-teaser-promote-url");
                    buildModal(false, promoTeaser).open();
                },
                false
            );
        });
    }
})();
