/**
 * AEM utilities
 * =============
 * Index
 *
 * - getEditorMode()
 * - getEnvironment()
 * - getPageTemplate()
 * - isAuthor()
 */

import { classListArray } from "./dom";
import { createElement } from "./element";

/**
 * Return the specific AEM editor view mode
 *
 * Possible returned values:
 * - DEVELOPER (Content view only)
 * - INITIAL (Template view only)
 * - LAYOUTING (Content & Templates views)
 * - PREVIEW (Content & Templates views)
 * - STRUCTURE (Template view only)
 * - TIMEWARP (Content view only)
 *
 * @return  {String|Undefined}
 */
export function getEditorMode() {
    const html = document.querySelector("html"); // No check, as page will always have HTML-tag
    const css = classListArray(html).filter((c) => c.includes("aem-AuthorLayer") && !c.includes("hidePlaceholder"));

    if (css.length == 0) {
        return;
    }

    return css[0].replace(/^aem-AuthorLayer-/i, "").toUpperCase();
}

/**
 * Determine AEM environment
 * - Filter-out localhost, DEV & UAT environments
 * - Assume anything else is PROD
 * @return  {String}
 */
export function getEnvironment() {
    const nonProdEnvironments = ["localhost", "DEV", "UAT"];
    const HOST = location.hostname.split(".")[0];
    return nonProdEnvironments.find((env) => HOST.toUpperCase() === env.toUpperCase()) || "PROD";
}

/**
 * Determine AEM page template from client-side J
 * @return  {String|Undefined}
 */
export function getPageTemplate() {
    const body = document.querySelector("body");
    const css = classListArray(body).filter((c) => c.includes("tmpl-"));

    if (css.length == 0) {
        return;
    }

    return css[0].replace(/^tmpl-/i, "").toUpperCase();
}

/**
 * Detect when AEM page is in author mode/environment;
 * as opposed to publish environment or query string `wcmmode=disabled`
 * @note: Repeated in FSSA project namespace for immediate use in page
 */
export function isAuthor() {
    return !!document.querySelector("html[data-mode='author']");
}

/**
 * Append AEM's `wcmmode=disabled` key/value query string item
 * Helper used to ensure AEM doesn't present Author/Editmode functionality
 * e.g. in linked AEM pages such as `iframe` testing on author
 * - Use sparingly and with much testing to ensure no unintended side effects
 *
 * @param   {String}  url
 * @return  {String}
 */
export function setWcmModeDisabled(url) {
    const a = createElement("a", {
        href: url
    });
    const queryString = a.search;
    const params = new URLSearchParams(queryString);
    params.set("wcmmode", "disabled");
    return params.toString();
}

export default {
    getEditorMode,
    getEnvironment,
    getPageTemplate,
    isAuthor,
    setWcmModeDisabled
};
