/**
 * Accordion Component
 * ===================
 * Calls on the Accordion API for initialization only.
 * Accordion component includes API data-attributes in its HTL;
 * no need for `Accordion.setup()` call.
 */

import Accordion from "../../../../global/js/api/accordion";
import { nodeListArray } from "../../../../global/js/utils/dom";

export default (function initAccordions() {
    const components = nodeListArray(document.querySelectorAll(".accordion[data-accordion]"));

    if (window.FSSA && !window.FSSA.isAuthor) {
        components.forEach((component) => {
            Accordion(component, {
                icon: "chevron",
                openOnLoad: component.getAttribute("data-open"),
                skipSetup: true,
                type: component.getAttribute("data-accordion")
            }).init();
        });
    }
})();
