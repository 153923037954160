// Constants
import EVENTS from "../../../../global/js/constants/events";

// Utils
import Panel from "../../../../global/js/utils/panel";
import Template from "../../../../global/js/utils/template";

import { runTranslations } from "../../../../global/js/utils/i18n";
import { nodeListArray } from "../../../../global/js/utils/dom";
import { focusableElements, isElement } from "../../../../global/js/utils/element";

const countrySelector = document.querySelector("#country-selector");
const DATA = {};
const ELEMENTS = {};

function setElements() {
    ELEMENTS.TITLE = countrySelector.querySelector("span[data-country-name]");
    ELEMENTS.BUTTON = countrySelector.querySelector("button[data-country-selector-toggle]");
    ELEMENTS.DRAWER = countrySelector.querySelector("#countrylist-options");
}

function getContinents(dataElement) {
    return JSON.parse(dataElement.getAttribute("data-model") || []);
}

function getCountries(data) {
    return data.reduce((collectedCountries, continent) => collectedCountries.concat(continent.countries), []);
}

function getCountry(countryCode) {
    const FOUND_COUNTRY = DATA.COUNTRIES.find((country) => country.country === countryCode);

    if (!FOUND_COUNTRY) {
        // Default to Global, if country not found
        return {
            country: "global",
            name: "Global"
        };
    }

    return FOUND_COUNTRY;
}

function setToCurrentLocale() {
    const { country, language, investorType } = window.FSSA.currentLocale;

    window.dispatchEvent(
        new CustomEvent(EVENTS.COUNTRY_SELECTOR.LOCALE_CHANGED, {
            detail: {
                country: getCountry(country),
                codes: {
                    country,
                    language,
                    investorType
                }
            }
        })
    );
}

function setCountryItemHtml(countryCode) {
    const country = getCountry(countryCode);
    const { languages = [], name } = country;

    return languages
        .map((language) => {
            // Amend multi-lingual locale name to append language identifier
            let text = languages.length > 1 ? `${name} (${language.name})` : name;
            if (text == 'Hong Kong (Chinese)') {
                text = "香港 (繁體中文)";
            }
            return Template("#template-countrySelectorListItem", {
                COUNTRY_CODE: countryCode,
                COUNTRY_NAME: text,
                LANGUAGE_CODE: language.language,
                LOCALE_DATA: JSON.stringify({
                    country: countryCode,
                    language: language.language
                })
            });
        })
        .join("");
}

function setContinentalLists() {
    return DATA.CONTINENTS.forEach((continentData) => {
        const { continent, countries = [] } = continentData;
        const list = countrySelector.querySelector(`section.continent--${continent} > ul`);
        const countryLists = countries.map((country) => setCountryItemHtml(country.country)).join("");
        list.innerHTML = countryLists;
    });
}

function updateTitleLocation() {
    ELEMENTS.BUTTON.hidden = false;

    window.addEventListener(
        EVENTS.COUNTRY_SELECTOR.LOCALE_CHANGED,
        (e) => {
            const { country, codes } = e.detail;

            let countryName = country.name;
            if(countryName == 'Hong Kong' && codes.language == 'zh'){
                countryName = "香港";
            }

            if (country.name.toUpperCase() !== "GLOBAL") {
                ELEMENTS.TITLE.textContent = countryName;
                ELEMENTS.BUTTON.textContent = ELEMENTS.BUTTON.getAttribute("data-text-change");
                runTranslations(codes.language);
            } else {
                // Different order of translation call is deliberate;
                // Global/Non-regional site(s) need to ensure 'Select' text returns
                // FYI: 'Select' only displays in English
                runTranslations(codes.language);
                ELEMENTS.TITLE.textContent = "";
                ELEMENTS.BUTTON.textContent = ELEMENTS.BUTTON.getAttribute("data-text-select");
                Panel.open(ELEMENTS.BUTTON, { immediate: true });
            }
        },
        false
    );
}

function countryLinksListener() {
    if (!isElement(ELEMENTS.DRAWER)) {
        return;
    }

    // Add single click listener to the continents drawer
    // More efficient that individual link click listeners
    ELEMENTS.DRAWER.addEventListener(
        "click",
        (e) => {
            e.preventDefault();
            const { target } = e;
            const a = target.closest("a[data-locale]");

            if (!isElement(a)) {
                return;
            }

            const localeData = JSON.parse(a.getAttribute("data-locale") || {});

            window.dispatchEvent(
                new CustomEvent(EVENTS.COUNTRY_SELECTOR.LOCALE_CHANGED, {
                    detail: {
                        country: getCountry(localeData.country),
                        codes: {
                            country: localeData.country,
                            language: localeData.language
                            // We don't about know investorType at this point
                        }
                    }
                })
            );

            Panel.close(ELEMENTS.BUTTON);
            ELEMENTS.BUTTON.focus();
        },
        false
    );
}

function selectButton() {
    ELEMENTS.DRAWER.hidden = true;
    ELEMENTS.DRAWER.classList.add("sliding-panel", "closed");
    ELEMENTS.BUTTON.addEventListener("click", (e) => Panel.toggle(ELEMENTS.BUTTON), false);

    const lastTabStop = nodeListArray(ELEMENTS.DRAWER.querySelectorAll(focusableElements)).pop();
    Panel.transitionEnd(ELEMENTS.DRAWER);

    lastTabStop.addEventListener(
        "keydown",
        (e) => {
            // Ignore non-tab key codes, or if shift key in use, i.e. tabbing backwards
            if (e.key.toUpperCase() !== "TAB" || e.shiftKey) {
                return () => {};
            }
            Panel.close(ELEMENTS.BUTTON);
            ELEMENTS.BUTTON.focus();
        },
        false
    );
}

function clickOutsideEvent(e) {
    const { target } = e;
    if (target === ELEMENTS.BUTTON || target.closest("#countrylist-options")) {
        return;
    }
    Panel.close(ELEMENTS.BUTTON);
}

export default (function initCountrySelector() {
    if (!isElement(countrySelector) || (window.FSSA && window.FSSA.isAuthor)) {
        return;
    }

    const dataElement = countrySelector.querySelector(".selector-layout");
    DATA.CONTINENTS = getContinents(dataElement);
    DATA.COUNTRIES = getCountries(DATA.CONTINENTS);

    setElements();
    setContinentalLists();
    selectButton();
    countryLinksListener();
    updateTitleLocation();

    setToCurrentLocale();
    window.addEventListener(
        EVENTS.MODAL_OVERLAY.MODAL_OPENED,
        (e) => {
            const { id, modal } = e.detail;
            if (id === "REGION_OVERLAY") {
                setToCurrentLocale();
                modal.addEventListener("click", clickOutsideEvent, false);
            }
        },
        false
    );

    window.addEventListener(
        EVENTS.MODAL_OVERLAY.MODAL_CLOSED,
        (e) => {
            const { id, modal } = e.detail;
            if (id === "REGION_OVERLAY") {
                modal.removeEventListener("click", clickOutsideEvent, false);
            }
        },
        false
    );

    countrySelector.classList.add("country-selector--modal");
    countrySelector.remove();
    window.FSSA.elementStore.countrySelector = countrySelector;
})();
