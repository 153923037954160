
// Utils
import { isElement } from "../../../../global/js/utils/element";

const select = document.querySelector(".tagsselect");
const options = document.querySelectorAll(".tagsselect option");



export default (function () {

    let urlParams = new URLSearchParams(location.search);
    if ((urlParams.has("utm_source") && urlParams.has("show_no_article_message")) || urlParams.has("show_no_vanity_message")) {
		const noArticleMessageElement = document.querySelector(".no-article-found-message");
		if (isElement(noArticleMessageElement)) {
		    noArticleMessageElement.hidden = false;
		} else {
            const firstPanelContainer = document.getElementsByClassName('panel-container')[0];
            if (isElement(firstPanelContainer)) {
                const firstPanelChild = firstPanelContainer.firstChild;
                if (isElement(firstPanelChild)) {
                    const noArticleMsgElement = document.createElement("h4");
                    noArticleMsgElement.classList.add("no-article-found-message");
                    noArticleMsgElement.append(window.FSSA.noPageFoundCustomMessage);
                    firstPanelChild.prepend(noArticleMsgElement);
                }
            }
		}
	}

    if (!isElement(select) && !isElement(options)) {
        return;
    }

	// 1
	var href;
	select.addEventListener('change', () => {
        const url = select.value;
  	  if(url) {
  	    location.href = url;
  	  }
    });
	 
	// 2
	for(const option of options) {
	  href = location.href;
	  if(href.includes("pageIndex")){
		  href = href.slice(href.lastIndexOf('&')+1, href.length);
	  }
	  else{
		  href = href.slice(href.lastIndexOf('?')+1,href.length);
	  }
	  let url = option.value;
	  url = url.substring(url.indexOf('?')+1,url.length);
	  if(href === url) {
	    option.setAttribute("selected", "");
	    break;
	  }
	}
	
})();